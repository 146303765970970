import React, { useState, Fragment, useEffect, useRef } from "react";
import styles from "./Assessment.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnAssessment,
  AssessmentData,
} from "../../../types/TablesTypes/Assessment";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import Mocked from "./assessment.json";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc, Divider } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { Grid, MenuItem } from "@material-ui/core";
import Udownload from "../../../assets/images/Icons/u_download-alt.svg";
import Edit from "../../../assets/images/Icons/u_edit-alt.svg";
import Mark from "../../../assets/images/Icons/u-mark.svg";
import Times from "../../../assets/images/Icons/u_multiply (1).svg";
import downloady from "../../../assets/images/Icons/downloady.svg";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import subsidiaryApi from "../../../api/subsidiaries";
import currencyApi from "../../../api/currencies";
import { serialize } from "object-to-formdata";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const Assessment = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);
  const [modalIsOpenAdd, setIsOpenAdd] = React.useState(false);
  const [assessment, setAssessment] = useState<any>();
  const [currency, setCurrency] = useState<string>("NGN");
  const ref = useRef<any>(null);
  const [file, setFile] = useState<any>();
  const handleChange = (e: any) => {
    console.log("image:", e.target.files[0]);
    setFile(e.target.files[0]);
  };
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];
  const getSubsidiariesApi = useApi(subsidiaryApi.getSubsidiaries);

  const fetchSubsidiaries = async () => {
    getSubsidiariesApi.request();
  };
  const subsidiaries = getSubsidiariesApi?.data?.items?.reduce(
    (memo: any, { name, id }: { name: string; id: number }) =>
      (memo = [...memo, { name: name, value: id }]),
    []
  );
  const getCurrenciesApi = useApi(currencyApi.getCurrencies);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data.reduce(
    (memo: any, { short_name }: { short_name: string }) =>
      (memo = [...memo, { name: short_name }]),
    []
  );

  useEffect(() => {
    fetchCurrencies();
    fetchSubsidiaries();
  }, []);
  const settlementOptions = [{ name: "Super Admin" }, { name: "Business" }];
  const currencyOptions = [{ name: "NGN" }, { name: "USD" }];

  const validate = Yup.object({
    subsidiary_id: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    cardscheme: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
    document: Yup.string().required("Required"),
  });

  function closeModalSingle() {
    setIsOpenSingle(false);
  }
  function closeModalEdit() {
    setIsOpenEdit(false);
  }
  function closeModalAdd() {
    setIsOpenAdd(false);
  }

  interface initTypes {
    subsidiary_id: number | undefined;
    amount: number | undefined;
    cardscheme: string;
    comment: string;
  }

  const initProps: initTypes = {
    subsidiary_id: undefined,
    amount: undefined,
    cardscheme: "",
    comment: "",
  };

  const [inputs, setInputs] = React.useState(initProps);
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const disableFunction = () => {
    if (
      !inputs.subsidiary_id ||
      !inputs.amount ||
      inputs.cardscheme === "" ||
      inputs.comment === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentmethod, setPaymentMethod] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [status, setStatus] = useState("");
  const [merchantName, setMerchantName] = useState("");

  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentMethod("");
    setSubsidiary("");
    setStatus("");
    setMerchantName("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Method",
      value: paymentmethod,
      setValue: setPaymentMethod,
      type: "text",
    },
    {
      name: "subsidiary Id",
      value: subsidiary,
      setValue: setSubsidiary,
      type: "text",
    },
  ];

  const fetchChargeback = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `fraud/risk/assessment/fees?merchantname=${merchantName}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
      );
      setAssessment(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchChargeback();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchChargeback();
    }
  }, [bearer, pageNumber, rowsPerPage, merchantName]);

  useEffect(() => {
    setPageNumber(assessment?.current_page || 1);
    setPreviousPage(assessment?.previous_page);
    setNextPage(assessment?.next_page);
  }, [assessment]);

  const uploadHandler = () => {
    dispatch(openLoader());
    const formData = serialize({ ...inputs, document: file });
    client
      .post("fraud/risk/add/assessment/fees", formData)
      .then((data: any) => {
        dispatch(closeLoader());
        closeModalAdd();
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles,
          })
        );

        fetchChargeback();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const Doc = () => {
    return <div>transaction</div>;
  };

  const dataTransaction = () => {
    const tempArr: AssessmentData[] = [];
    assessment?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          merchantName: transaction.merchantName,
          cardScheme: transaction.cardScheme,
          amount: transaction.amount,
          document: <Doc />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [assessment?.data?.items]);

  const itemLength = assessment?.length;

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
          ),
        },
      ]}
    />
  );
  // newButton={
  // 				<MenuFunc overlay={menu}>
  // 					<div className={styles.createLink}>Log Refund</div>
  // 				</MenuFunc>
  // 			}

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      <TableHeader
        pageName="Assessment Fee"
        data={assessment?.data?.items}
        dataLength={assessment?.data?.page?.total}
        value={merchantName}
        setValue={setMerchantName}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search by Merchant Name"
        exporting={false}
        filtering={false}
        newButton={
          <div onClick={() => setIsOpenAdd(true)} className={styles.createLink}>
            Add assessment fee
          </div>
        }
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnAssessment ? ColumnAssessment : []}
        value={value}
        emptyPlaceHolder={
          assessment?.data?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={assessment?.data?.page?.total}
        totalPage={assessment?.data?.page?.total_page}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* //MODAL FOR DETAILS */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Assessment fee</h3>
            <CloseIcon
              onClick={closeModalSingle}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Amount</div>
                  <div className={styles.detailsKey}>NGN 500,000</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Card scheme</div>
                  <div className={styles.detailsKey}>VISA</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Comment</div>
                  <div className={styles.detailsKey}>
                    I don’t know what type of assessment fee that transpired
                    between the merchant and TransactPay
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Document</div>
                  <div className={styles.detailsPreview}></div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <button
                  onClick={() => setIsOpenEdit(true)}
                  className={styles.Downloadbutton_faint}
                >
                  <span className={styles.Downloadbutton_span}>
                    <img src={Edit} alt="" />
                  </span>
                  Edit document
                </button>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <button className={styles.Downloadbutton}>
                  <span className={styles.Downloadbutton_span}>
                    <img src={Udownload} alt="" />
                  </span>
                  Download information
                </button>
              </Grid>{" "}
            </Grid>
          </div>
        </div>
      </Modal>

      {/* //MODAL FOR EDIT */}
      <Modal
        isOpen={modalIsOpenEdit}
        onRequestClose={closeModalEdit}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Edit Assessment fee</h3>
            <CloseIcon onClick={closeModalEdit} style={{ cursor: "pointer" }} />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="merchant_name"
                    inputLabel="Merchant name"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfoAmount}>
                  <div className={styles.inputAmount}>
                    <OutlineTextInput
                      inputName="currency"
                      inputLabel=""
                      inputSelect={true}
                      handleChange={(e) => setCurrency(e.target.value)}
                      inputValue={currency}
                      inputOption={currencyOptions}
                    />
                  </div>
                  <div className={styles.inputAmountInput}>
                    <OutlineTextInput inputName="amount" inputLabel="Amount" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="card_scheme"
                    inputLabel="Card scheme"
                    inputSelect={true}
                    inputOption={settlementOptions}
                    InputHelper={
                      <ErrorMessage name="card_scheme">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    }
                  />
                </div>
              </Grid>{" "}
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="comment"
                    inputLabel="Comment"
                    multiline
                  />
                </div>
              </Grid>{" "}
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.modalupload}>
                    <img src={downloady} alt="" />

                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => ref.current.click()}
                    >
                      <button>Choose file to upload</button>
                    </label>
                    <input
                      type="file"
                      name="avatar"
                      id="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={handleChange}
                      ref={ref}
                      hidden
                    />
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <button className={styles.Downloadbutton_faint}>
                  <span className={styles.Downloadbutton_span}>
                    <img src={Times} alt="" />
                  </span>
                  Cancel
                </button>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <button className={styles.Downloadbutton}>
                  <span className={styles.Downloadbutton_span}>
                    <img src={Mark} alt="" />
                  </span>
                  Upload
                </button>
              </Grid>{" "}
            </Grid>
          </div>
        </div>
      </Modal>

      {/* //MODAL FOR ADD */}
      <Modal
        isOpen={modalIsOpenAdd}
        onRequestClose={closeModalAdd}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Add Assessment fee</h3>
            <CloseIcon onClick={closeModalAdd} style={{ cursor: "pointer" }} />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="subsidiary_id"
                    inputLabel="Subsidiary"
                    inputSelect={true}
                    handleChange={updateProps}
                    inputValue={inputs.subsidiary_id}
                    inputOption={subsidiaries}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfoAmount}>
                  <div className={styles.inputAmount}>
                    <OutlineTextInput
                      inputName="currency"
                      inputLabel=""
                      inputSelect={true}
                      handleChange={updateProps}
                      inputValue={currency}
                      inputOption={currencies}
                    />
                  </div>
                  <div className={styles.inputAmountInput}>
                    <OutlineTextInput
                      inputName="amount"
                      inputLabel="Amount"
                      inputValue={inputs.amount}
                      handleChange={updateProps}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="cardscheme"
                    inputLabel="Card Scheme"
                    inputValue={inputs.cardscheme}
                    handleChange={updateProps}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <OutlineTextInput
                    inputName="comment"
                    inputLabel="Comment"
                    inputValue={inputs.comment}
                    handleChange={updateProps}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.customerInfo}>
                  <div className={styles.modalupload}>
                    <img src={downloady} alt="" />

                    <label
                      style={{ cursor: "pointer" }}
                      onClick={() => ref.current.click()}
                    >
                      <button>Choose file to upload</button>
                    </label>
                    <input
                      type="file"
                      name="avatar"
                      id="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={handleChange}
                      ref={ref}
                      hidden
                    />
                    {file && file?.name}
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <button className={styles.Downloadbutton_faint}>
                  <span className={styles.Downloadbutton_span}>
                    <img src={Times} alt="" />
                  </span>
                  Cancel
                </button>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <button
                  disabled={disableFunction()}
                  onClick={uploadHandler}
                  className={styles.Downloadbutton}
                >
                  <span className={styles.Downloadbutton_span}>
                    <img src={Mark} alt="" />
                  </span>
                  Upload
                </button>
              </Grid>{" "}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Assessment;
