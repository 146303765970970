import React, { useState, Fragment, useEffect } from "react";
import styles from "./Balancehistory.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  BalanceHistoryData,
  ColumnBalanceHistory,
} from "../../../types/TablesTypes/BalanceHistory";
import TableHeader from "../../../components/TableHeader/TableHeader";
import MenuOption from "../../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
// import balanceHistoryApi from '../../../api/balanceHistory';
import useApi from "../../../hooks/useApi";
import { numberWithCommas } from "../../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import PaymentLinkMenu from "../../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
// import CreatePaymentLink from '../../../components/ModalContent/CreatePaymentLink/CreatePaymentLink';
import moment from "moment";
import { useParams } from "react-router-dom";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import client from "../../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import StatusView from "../../../components/StatusView/StatusView";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import {
  dateNow,
  endOfYear,
  sevenDaysAgo,
  startOfYear,
  thirtyDaysAgo,
} from "../../../utils/datefunction";
import { dateEventFilters } from "../../../constants/date-event-filters";

const BalanceHistory = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const { id } = useParams<{ id: any }>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [allcustomerDetails, setAllcustomerDetails] = useState<any>([]);
  const [direction, setDirection] = useState<string>("");
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.appSettingsReducer);

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);;
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [reference, setReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setReference("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);
  const filteredArray: {
    id?: number;
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      id: 1,
      name: "Direction",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Debit", value: "D" },
        { name: "Credit", value: "C" },
      ],
      type: "select",
    },
    {
      id: 2,
      name: "Reference",
      value: reference,
      setValue: setReference,
      type: "text",
    },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  const fetchBalanceHistory = () => {
    dispatch(openLoader());
    client
      .get(
        `/wallet/${id}/history?currency=${currency}&direction=${status}&reference=${reference}&limit=${rowsPerPage}&page=${pageNumber}&fromdate=${fromDate}&todate=${toDate}`
      )
      .then((res: any) => {
        setCustomerDetails(res);
        dispatch(closeLoader());
        setBearer(false);
      })
      .catch((error) => {
        dispatch(closeLoader());
        setBearer(false);

        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const fetchAllBalanceHistory = async () => {
    dispatch(openLoader());

    client
      .get(
        `/wallet/${id}/history?currency=${currency}&direction=${status}&reference=${reference}&limit=${5000}&fromdate=${fromDate}&todate=${toDate}&download=true`
      )
      .then((res: any) => {
        setCustomerDetails(res);
        dispatch(closeLoader());
        // setBearer(false);
        setAllcustomerDetails(res);
        return res;
      })
      .catch((error) => {
        dispatch(closeLoader());
        setBearer(false);

        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    fetchBalanceHistory();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchBalanceHistory();
    }
    fetchAllBalanceHistory();
  }, [bearer, currency, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(customerDetails?.data?.currentPage || 1);
    setPreviousPage(customerDetails?.data?.currentPage - 1);
    setNextPage(customerDetails?.data?.currentPage + 1);
  }, [customerDetails]);

  const dataPaymentLinks = () => {
    const tempArr: BalanceHistoryData[] = [];
    customerDetails?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((balance: any, index: number) => {
        tempArr.push({
          date: moment(balance?.dateCreated).format("MMMM Do YYYY, h:mm a"),
          amount: `${currency} ${numberWithCommas(balance?.amount)}`,
          direction: (
            <>
              <StatusView
                status={balance?.direction}
                green={"Credit"}
                red={"Debit"}
              />
            </>
          ),
          balance: `${currency} ${numberWithCommas(balance?.balance)}`,
          details: balance?.remarks,
          // action: <BalanceMenu data={balance} />,
        });
      });
    return tempArr;
  };
  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Balance History"
        data={allcustomerDetails?.data?.items}
        // data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.totalItems}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        fetchDataHandler={fetchAllBalanceHistory}
        exporting={true}
        FilterComponent={
          <>
            <FilterButtonNew
              // eventDate={eventDate}
              // setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              applyAllHandler={applyAllHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}
              setEventDate={setEventDate}
            />
          </>
        }
      />
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnBalanceHistory ? ColumnBalanceHistory : []}
          emptyPlaceHolder="You currently do not have any balance history"
          total={customerDetails?.data?.totalItems}
          totalPage={customerDetails?.data?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </Fragment>
  );
};

export default BalanceHistory;
