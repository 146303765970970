import React, { useState, Fragment, useEffect } from "react";
import styles from "./Settlements.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnSettlementsFull,
  SettlementsFullData,
} from "../../../types/TablesTypes/Settlementsful";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc } from "antd";
import Modal from "react-modal";
import {
  computeCustomStyles,
  customStyles,
} from "../../../constants/modalStyles";
import Single from "./Single";
import Bulk from "./Bulk";
import SettlementFMenu from "../../../components/MenuOption/SettlementFMenu/SettlementFMenu";
import dayjs, { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import settlements from "../../../api/settlements";
import SettlementTransactions from "./SettlementTransactions/SettlementTransactions";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const Settlements = ({ subsidiaryId }: any) => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalSettlementTransaction, setModalSettlementTransaction] =
    React.useState({
      isOpened: false,
      data: null,
    });
  const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
  const allSettlementApi = useApi(settlements.getAllSettlements);
  const settlement = allSettlementApi?.data?.data || {};
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];

  const { currency } = useSelector((state) => state.appSettingsReducer ?? {});

  function closeModalSingle() {
    setIsOpenSingle(false);
  }

  function closeTransactionModalSingle() {
    setModalSettlementTransaction({
      isOpened: false,
      data: null,
    });
    fetchSettlement();
  }
  function closeModalBulk() {
    setIsOpenBulk(false);
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [bearer, setBearer] = useState(false);
  const [bulkActionData, setBulkActionData] = useState<any>({});

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Pending", value: "Pending" },
        { name: "Flagged", value: "Flagged" },
        { name: "Cancelled", value: "Cancelled" },
        { name: "Partial Completed", value: "Partial Completed" },
        { name: "Completed", value: "Completed" },
        { name: "Ongoing", value: "Ongoing" },
        { name: "Failed", value: "Failed" },
        { name: "Awaiting Disbursement", value: "Awaiting Disbursement" },
        { name: "Pending Disbursement", value: "Pending Disbursement" },
      ],
    },
  ];

  const fetchSettlement = async (isExport = false) => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      if (subsidiaryId == undefined) subsidiaryId = "";
      var data = await allSettlementApi.request(
        `subsidiaryId=${subsidiaryId}&subsidiaryName=${email}&currency=${currency}&settlementstatus=${status}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}&export=${isExport}`
      );
      setBearer(false);
      return data.items;
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlement();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchSettlement();
    }
  }, [bearer, pageNumber, rowsPerPage, email, currency]);

  useEffect(() => {
    setBearer(true);
  }, [pageNumber, email, rowsPerPage, currency]);

  useEffect(() => {
    // setPageNumber(settlement?.page?.currentPage || 1);
    // setPreviousPage(settlement?.page?.previousPage || 1);
    // setNextPage(settlement?.page?.nextPage || 2);
    setTableRow(dataSettlement());
    // setSettlement(_settlement);
  }, [allSettlementApi.data]);

  const openTransactionDetails = async (settlement: any) => {
    setModalSettlementTransaction({ isOpened: true, data: settlement });
  };

  const processBulkSettlement = async () => {
    console.log("Processing bulk payment");
    console.log(bulkActionData);
    const payload = [];
    for (const key in bulkActionData) {
      if (key == "header") continue;
      const record = bulkActionData[key];
      if (!record.checkboxMarked) continue;
      if (record._status != "Pending") continue;
      const fromdate =
        moment(record?.dateCreated).format("yyy-MM-DD") + " 00:00:00";
      const todate =
        moment(record?.dateCreated).format("yyy-MM-DD") + " 23:59:59";
      const _payload = {
        status: record?._status,
        currency: record?.currency,
        fromdate: fromdate,
        todate: todate,
        subsidiaryId: record?.merchantId,
      };
      payload.push(_payload);
    }
    processBulkSettlementHandler(payload);
  };

  const processBulkSettlementHandler = async (payload: any) => {
    try {
      console.log("Processing bulk payment");
      console.log(payload);
      dispatch(openLoader());
      settlements
        .processBulkSettlement(payload)
        .then((d: any) => {
          dispatch(
            openToastAndSetContent({
              toastContent: d?.data?.message,
              toastStyles: ToastSuccessStyles,
            })
          );
        })
        .catch((error) => {
          const { message } = error.response.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        })
        .finally(() => {
          dispatch(closeLoader());
          fetchSettlement();
        });
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  const dataSettlement = () => {
    const tempArr: SettlementsFullData[] = [];
    const _settlement = settlement?.items || [];
    _settlement
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        tempArr.push({
          businessName: settlement.subsidiaryName,
          destination: settlement.settlementDestination,
          merchantId: settlement.subsidiaryId,
          currency: settlement.currency,
          dateCreated: settlement.finalSettlementDate,
          transactionCount: settlement.transactionCount,
          id: `STMNT-${settlement.subsidiaryId}${settlement?.settlementStatus}${settlement.currency}${settlement?.finalSettlementDate}`,
          amount: `${settlement.currency} ${numberWithCommas(
            settlement?.settlementAmount
          )}`,
          date: moment(settlement?.finalSettlementDate).format(
            "MMMM Do YYYY, h:mm a"
          ),
          _status: settlement?.settlementStatus,
          status: (
            <StatusView
              status={settlement?.settlementStatus}
              green="Completed"
              red="Failed"
              orange="Awaiting Approval | Iniated"
            />
          ),
          // action: <SettlementFMenu customerId={settlement.subsidiary_id} />,
        });
      });
    return tempArr;
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p onClick={() => setIsOpenSingle(true)}>Log a single settlement</p>
          ),
        },
        {
          key: "2",
          label: (
            <p onClick={() => setIsOpenBulk(true)}>Log bulk settlements</p>
          ),
        },
      ]}
    />
  );

  return (
    <div className={styles.dashbordContainer}>
      <TableHeader
        pageName="All Settlement"
        data={settlement?.items}
        dataLength={settlement?.page?.total}
        value={email}
        setValue={setEmail}
        dropdown={dropdown}
        setDropdown={setDropdown}
        fetchDataHandler={() => fetchSettlement(true)}
        searchfn={true}
        placeHolder="Search by name"
        newButton={
          <div className={styles.tableButtonWrapper}>
            <div onClick={processBulkSettlement} className={styles.createLink}>
              Process Bulk
            </div>
            <MenuFunc overlay={menu}>
              <div className={styles.createLink}>Log settlement</div>
            </MenuFunc>
          </div>
        }
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearAllHandler={clearHandler}
              applyAllHandler={applyAllHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnSettlementsFull ? ColumnSettlementsFull : []}
        value={value}
        emptyPlaceHolder={
          settlement?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={settlement?.page?.total}
        totalPage={settlement?.page?.totalPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        allowBulkAction={true}
        updateBulkActionData={setBulkActionData}
        clickAction={(obj) => openTransactionDetails(obj)}
      />

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Single closeModal={closeModalSingle} fn={fetchSettlement} />
      </Modal>
      {/* //MODAL FOR BULK */}
      <Modal
        isOpen={modalIsOpenBulk}
        onRequestClose={closeModalBulk}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Bulk closeModal={closeModalBulk} fn={fetchSettlement} />
      </Modal>
      <Modal
        isOpen={modalSettlementTransaction.isOpened}
        onRequestClose={closeTransactionModalSingle}
        contentLabel="Example Modal"
        style={computeCustomStyles("5%")}
      >
        <SettlementTransactions
          closeModal={closeTransactionModalSingle}
          data={modalSettlementTransaction.data}
        />
      </Modal>
    </div>
  );
};

export default Settlements;
