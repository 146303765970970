import React, { useState, useEffect } from "react";
import styles from "./Users.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnUsers, UsersData } from "../../../types/TablesTypes/Users";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import usersApi from "../../../api/users";
import rolesApi from "../../../api/roles";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import UserMenu from "../../../components/MenuOption/UserMenu/UserMenu";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import Modal from "react-modal";
import AddAdminUser from "../../../components/ModalContent/AddAdminUser/AddAdminUser";
import { customStyles } from "../../../constants/modalStyles";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const tableRoles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Compliance" },
  { id: 3, name: "Settlement Ops" },
];

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableRow, setTableRow] = useState<any[]>();
  const [users, setUsers] = useState<any>();
  const getUsersApi = useApi(usersApi.getUsers);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [email, setEmail] = useState("");

  const clearHandler = () => {
    setRole("");
    setStatus("");
    setEmail("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  const getRolesApi = useApi(rolesApi.getRoles);

  const fetchRoles = async () => {
    getRolesApi.request();
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const rolesData = getRolesApi?.data?.data;

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Role",
      value: role,
      setValue: setRole,
      selective: rolesData,
      type: "select",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "InActive", value: "InActive" },
        { name: "Active", value: "Active" },
      ],
      type: "select",
    },
  ];

  const fetchUsers = async () => {
    role.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `users/all/paginated?status=${status}&role=${role}&email=${email}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setUsers(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchUsers();
    }
  }, [bearer]);

  useEffect(() => {
    setBearer(true);
  }, [pageNumber, email, rowsPerPage]);

  useEffect(() => {
    setPageNumber(users?.currentPage || 1);
    setPreviousPage(users?.previousPage);
    setNextPage(users?.currentPage + 1);
  }, [users]);

  const dataUsers = () => {
    const tempArr: UsersData[] = [];
    users?.items
      ?.slice(0)
      .reverse()
      .forEach((user: any, index: number) => {
        return tempArr.push({
          name: `${user?.firstName} ${user?.lastName}`,
          role: user?.role,
          emailAddress: user?.emailAddress,
          status: (
            <StatusView
              status={user?.status}
              green="Active"
              red="InActive"
              orange="New"
            />
          ),
          action: <UserMenu userInfo={user} fetchUsers={fetchUsers} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataUsers());
  }, [users?.items]);

  const itemLength = users?.items?.length;
  return (
    <div style={{ minHeight: "100vh" }}>
      <TableHeader
        pageName="Users/Admins"
        data={users?.items}
        dataLength={users?.totalItemss}
        value={email}
        setValue={setEmail}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search by email"
        FilterComponent={
          <FilterButtonNew
            dropdown={dropdown}
            setDropdown={setDropdown}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
        newButton={
          <div onClick={() => setIsOpen(true)} className={styles.createLink}>
            Add User
          </div>
        }
      />

      {getUsersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnUsers ? ColumnUsers : []}
          value={value}
          emptyPlaceHolder={
            users?.totalItems == 0
              ? "You currently do not have any data"
              : "Loading..."
          }
          total={users?.totalItems}
          totalPage={users?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}

      <Footer />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <AddAdminUser
          fetchCustomers={fetchUsers}
          closeModal={closeModal}
          roles={rolesData}
        />
      </Modal>
    </div>
  );
};

export default User;
