import React, { useState, useEffect } from 'react';
import styles from './ApproveRefund.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ApproveColumnRefund, ApproveRefundData } from '../../../types/TablesTypes/Refund';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import {openLoader, closeLoader } from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { ToastSuccessStyles, ToastErrorStyles, } from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import FilterButtonNew from '../../../components/FilterButtonNew/FilterButtonNew';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';
import StatusView from '../../../components/StatusView/StatusView';
import RefundsMenu from '../../../components/MenuOption/RefundsMenu/RefundsMenu';
import ApproveRefundMenu from '../../../components/MenuOption/ApproveRefundMenu/ApproveRefundMenu';
import { Modal } from 'antd';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
const MODAL_TYPES = {
	CONFIRM_TRANSACTION: "CONFIRM_TRANSACTION",
	VALIDATE_OTP: "VALIDATE_OTP"
}
const ApproveRefund = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [refund, setRefund] = useState<any>();
	const [bulkActionData, setBulkActionData] = useState<any>({});
	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [modalType, setModalType] = useState("")
	const [actionType, setActionType] = useState<boolean | null>(null)

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>(thirtyDaysAgo);
	const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
	const [paymentreference, setPaymentReference] = useState('');
	const [orderReference, setOrderReference] = useState('');
	const [status, setStatus] = useState('PendingApproval');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);
	const [name, setName] = useState('');
	const [otp, setOtp] = useState("")

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	const applyAllHandler = () => {
		// Ensure bearer triggers a re-fetch of transactions
		setBearer(true);
		setDropdown(false); 
	};
	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days' || !eventDate) {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Order Reference',
			value: orderReference,
			setValue: setOrderReference,
			type:'text',
		},
		{
			name: 'Customer Name',
			value: name,
			setValue: setName,
			type:'text'
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			type:'select',
			selective: [
				{ name: 'Pending Approval', value:'PendingApproval' },
				{ name: 'Completed', value:'Completed' },
				{ name: 'Declined', value:'Declined' },
				{ name: 'Approved', value:'Approved' },
				{ name: 'Processing', value:'Processing' },
				{ name: 'Awaiting Response', value:'Awaiting Response' },
			],
		},
	];

	const fetchRefund = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/refund/all/paginated?status=${status}&orderreference=${orderReference}&customername=${name}&email=${email}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setRefund(data);
			setPageNumber((data as any)?.currentPage || 1);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	const processBulkRefunds = async () => {		
		const payload: any = {refundId: []};
		
		for (const key in bulkActionData) {
			if (key === "header") continue; // Skip header, if it exists
			
			const record = bulkActionData[key];
			
			if (!record.checkboxMarked) continue; // Skip unchecked records
	
			// Construct the payload
			const _payload = {
				refundId: record?.refundId, // Refund ID
				customerName: record?.businessName, // Business name
			};
			
			payload.refundId.push(record?.refundId); // Add to payload array
		}
	
		handleToggleModal(MODAL_TYPES.CONFIRM_TRANSACTION)
	};

	useEffect(() => {
		fetchRefund();
	}, []);

	useEffect(() => {
		if (bearer) {
			fetchRefund();
		}
	}, [bearer, email, pageNumber, rowsPerPage]);

	useEffect(() => {
		setBearer(true);
	}, [pageNumber, email, rowsPerPage]);


	const dataTransaction = () => {
		const tempArr: ApproveRefundData[] = [];
		refund?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					amount: `${transaction.currency} ${numberWithCommas(transaction?.refundAmount)}`,
					merchantId: transaction.customerId,
					amountCharged: transaction.amount,
					businessName: transaction.customerName,
					transactionReference: transaction?.orderReference,
					date: moment(transaction?.dateCreated).format('MMMM Do YYYY, h:mm a'),
					id: transaction.id,
					refundId: transaction.id,
					status: (
						<StatusView
							status={transaction?.refundStatus}
							green='Approved'
							red='Declined'
							orange='Processing'
							blue='Awaiting Response'
						/>
					),
					// action: <RefundsMenu customerId={transaction.customer_id} />,
					action: <ApproveRefundMenu data={[transaction.id]}  />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [refund?.items]);

	const handleToggleModal = (modalType?: string) => {
		if (!modalType) {
			setOpenModal(false);
			setModalType("")
			setActionType(null)
			return;
		}
		setModalType(modalType)
		setOpenModal(true)
	}

	const ApproveRefund = async (refundIds: string[]) => {
		dispatch(openLoader());
		try {
		  const res: any = await client.post('/refund/approve', { refundIds });
		  const message = res?.data?.message;
		  dispatch(
			openToastAndSetContent({
			  toastStyles: ToastSuccessStyles,
			  toastContent: message,
			})
		  );
		//   setVerifyPayoutModalIsOpen(true);
		//   closeMenu();
		} catch (error: any) {
		  const message = error?.response?.data?.message || "An error occurred.";
		  dispatch(
			openToastAndSetContent({
			  toastStyles: ToastErrorStyles,
			  toastContent: message,
			})
		  );
		} finally {
		  dispatch(closeLoader());
		}
	  };

	const ConfirmRefund = async (refundIds: string[], otp: string, isApproved: null | boolean) => {
		dispatch(openLoader());
		try {
			const res: any = await client.post('/refund/confirm', { otp, refundIds, isApproved });
			const message = res?.data?.message;
			dispatch(
			openToastAndSetContent({
				toastStyles: ToastSuccessStyles,
				toastContent: message,
			})
			);
			// closeModal && closeModal();
		} catch (error: any) {
			const  message  = error?.response?.data?.message ?? 'An error occured';
			dispatch(
			openToastAndSetContent({
				toastStyles: ToastErrorStyles,
				toastContent: message,
			})
			);
		}
		finally {
			dispatch(closeLoader());
		}
	};
	  
	const handlePayoutAction = async (action: boolean) => {
		setActionType(action)
		try {
			const payload: any = {refundId: [], action};
			for (const key in bulkActionData) {
				if (key === "header") continue;
				const record = bulkActionData[key];
				if (!record.checkboxMarked) continue;
				// Construct the payload
				const _payload = {
					refundId: record?.refundId,
					customerName: record?.businessName,
				};
				payload.refundId.push(record?.refundId);
			}
			// call the initial approve api 
			await ApproveRefund(payload.refundId)
			// open verification modal if api call is succesful
			handleToggleModal(MODAL_TYPES.VALIDATE_OTP)
		} catch (error) {
		}
	}

	const handleSubmitOtp = () => {
		try {
			const payload: any = {refundId: [], otp ,action: actionType};	
			for (const key in bulkActionData) {
				if (key === "header") continue; // Skip header, if it exists
				
				const record = bulkActionData[key];
				
				if (!record.checkboxMarked) continue; // Skip unchecked records
		
				// Construct the payload
				const _payload = {
					refundId: record?.refundId, // Refund ID
					customerName: record?.businessName, // Business name
				};
				
				payload.refundId.push(record?.refundId); // Add to payload array
			}
			ConfirmRefund(payload.refundId, otp, actionType) 
			setOtp("") // clear otp
			handleToggleModal() // close modal
			// refetch payouts
		} catch (error: any) {
			const message = error?.response?.data?.message || "An error occurred.";
			dispatch(
			  openToastAndSetContent({
				toastStyles: ToastErrorStyles,
				toastContent: message,
			  })
			);
		  } finally {
			dispatch(closeLoader());
		  }
	}
	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='Refunds'
				dataLength={refund?.totalItems}
				data={refund?.items}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				placeHolder='search by email address'
				setDropdown={setDropdown}
				searchfn={true}
				newButton={
					  <div className={styles.createLink} onClick={processBulkRefunds}>Process Bulk</div>
				  }
				FilterComponent={
					<FilterButtonNew
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						clearAllHandler={clearHandler}
						applyAllHandler={applyAllHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ApproveColumnRefund ? ApproveColumnRefund : []}
				value={value}
				emptyPlaceHolder={
					refund?.totalItems == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={refund?.totalItems}
				totalPage={refund?.totalPages}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				allowBulkAction={true}
				updateBulkActionData={setBulkActionData}
			/>
			
			<Modal
				open={openModal}
				onCancel={() => handleToggleModal()}
				// contentLabel="Example Modal"
				// style={customStyles}
				centered
				footer={null}
			>
				{
					modalType === MODAL_TYPES.CONFIRM_TRANSACTION && <>
					<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyItems:'center'}}>
						<h3>Select Action</h3>
						<p>Please select the action you want to perform</p>
						<div>
							<button className={styles.decline} onClick={() => handlePayoutAction(false)}>Decline Refund</button> 
							<button className={styles.approvePayout} onClick={() => handlePayoutAction(true)}>Approve Refund</button>
						</div>
					</div>
					</>
				}
				{
					modalType === MODAL_TYPES.VALIDATE_OTP && <>

					{/* Otp field */}
					{/* <form onSubmit={handleSubmitOtp}>
						<input value={otp} onChange={e => setOtp(e.target.value)}/>
						<button>Submit</button>
					</form> */}
					
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmitOtp} className={styles.form}>
							<p>{"Enter the One-Time Password (OTP) sent to your email"}</p>
							<OutlineTextInput
								handleChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setOtp(e.target.value)}
								inputName="otp"
								inputLabel="One-Time Password (OTP)"
								inputValue={otp}
							/>
						</form>
						<div className={styles.modalFooter}>
							<div className={styles.decline} onClick={() => handleToggleModal()}>
								Cancel
							</div>
							<button 
								className={styles.approvePayout} 
								onClick={otp ? handleSubmitOtp : undefined} 
								disabled={!otp}
							>
								Verify Refund
							</button>

						</div>
					</div>

					</>
				}
			</Modal>
	  
		</div>
	);
};

export default ApproveRefund;
