import React, { useState, Fragment, useEffect } from 'react';
import styles from './Sales.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import { ColumnSales, SalesData } from '../../../types/TablesTypes/Sales';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { ToastErrorStyles } from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { Dayjs } from 'dayjs';
import FilterModal from '../../../components/filterConfig/FilterModal';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../utils/datefunction';
import FilterButtonNew from '../../../components/FilterButtonNew/FilterButtonNew';
import { dateEventFilters } from '../../../constants/date-event-filters';

const Sales = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [sales, setSales] = useState<any>();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
	const [fromDate, setFromDate] = useState<Dayjs | null | string>(thirtyDaysAgo);
	const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
	const [paymentmethod, setPaymentMethod] = useState('');
	const [subsidiary, setSubsidiary] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentMethod('');
		setSubsidiary('');
		setStatus('');
	};
	const applyAllHandler = () => {
		// Ensure bearer triggers a re-fetch of transactions
		setBearer(true);
		setDropdown(false); 
	};
	useEffect(() => {
		if (eventDate === dateEventFilters.today) {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === dateEventFilters.last7days) {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === dateEventFilters.last30days) {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === dateEventFilters.oneyear) {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray: {
		name: string;
		value: string | Dayjs | null;
		setValue: React.Dispatch<React.SetStateAction<any>>;
		selective?: { name: string; value: string }[];
		selectHelper?: boolean;
		type: "select" | "date" | "text";
	  }[]  = [
		{
			name: "Date Range",
			value: fromDate,
			setValue: setFromDate,
			type: "date", // Explicitly set as "date"
		},
		{
			name: 'Payment Method',
			value: paymentmethod,
			setValue: setPaymentMethod,
			type:'select',
			selective: [
				{
					name: 'Bank Transfer',
					value: 'BT',
				},
				{
					name: 'Card Payment',
					value: 'C',
				},
				{
					name: 'Bank Account',
					value: 'BA',
				},
				{
					name: 'USSD',
					value: 'USSD',
				},
				{
					name: 'Mobile Money',
					value: 'MOMO',
				},
				{
					name: 'Pay With Bank Transfer',
					value: 'BANK-TRANSFER',
				},
				{
					name: 'QR Code Payment',
					value: 'NQR',
				},
			],
		},
		{
			name: 'subsidiary Id',
			value: subsidiary,
			setValue: setSubsidiary,
			type:'text'
		},
	];

	const fetchSales = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`fraud/risk/sales/all?paymentmethod=${paymentmethod}&subsidiaryid=${subsidiary}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
			);
			setSales(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchSales();
	}, []);

	useEffect(() => {
		if (bearer) {
			fetchSales();
		}
	}, [bearer, pageNumber, rowsPerPage]);

	const dataTransaction = () => {
		const tempArr: SalesData[] = [];
		sales?.data?.items
			?.slice(0)
			.reverse()
			.forEach((transaction: any, index: number) => {
				tempArr.push({
					merchantId: transaction.subsidiaryId,
					merchantName: transaction.subsidiaryName,
					volume: transaction.volume,
					value: transaction?.value,
					account: transaction?.bank_name || 'Nil',
					// action: <RefundsMenu customerId={transaction.customer_id} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [sales?.data?.items]);

	useEffect(() => {
		setPageNumber(sales?.data?.page?.currentPage || 1);
		setPreviousPage(sales?.data?.page?.previousPage);
		setNextPage(sales?.data?.page?.nextPage);
	}, [sales?.data]);

	const itemLength = sales?.data?.items?.length;

	return (
		<div style={{ marginTop: '38px', minHeight: '100vh' }}>
			<TableHeader
				pageName='All Sales'
				data={sales?.data?.items}
				dataLength={sales?.data?.page?.total}
				value={subsidiary}
				setValue={setSubsidiary}
				dropdown={dropdown}
				setDropdown={setDropdown}
				searchfn={false}
				FilterComponent={
					<FilterButtonNew
						eventDate={eventDate}
						setEventDate={setEventDate}
						dropdown={dropdown}
						setDropdown={setDropdown}
						setFromDate={setFromDate}
						setToDate={setToDate}
						fromDate={fromDate}
						toDate={toDate}
						setBearer={setBearer}
						applyAllHandler={clearHandler}
						clearAllHandler={clearHandler}
						filteredArray={filteredArray}
					/>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnSales ? ColumnSales : []}
				value={value}
				emptyPlaceHolder={
					sales?.data?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading.......'
				}
				total={sales?.data?.page?.total}
				totalPage={sales?.data?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</div>
	);
};

export default Sales;
