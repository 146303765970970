import React, { useState, Fragment, useEffect } from "react";
import styles from "./RollingReserve.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  RollingReserveData,
  ColumnRollingReserve,
} from "../../../types/TablesTypes/RollingReserve";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { numberWithCommas } from "../../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import moment from "moment";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import client from "../../../api/client";
import { ToastErrorStyles } from "../../../constants/toastStyles";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import StatusView from "../../../components/StatusView/StatusView";
import FilterModal from "../../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import {
  thirtyDaysAgo,
  dateNow,
  endOfYear,
  sevenDaysAgo,
  startOfYear,
} from "../../../utils/datefunction";
import { dateEventFilters } from "../../../constants/date-event-filters";

const RollingReserve = () => {
  const params: any = useParams();
  const id = params.id;
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [dropdown, setDropdown] = useState(false);
  const { currency } = useSelector((state) => state.appSettingsReducer);
  const dispatch = useDispatch();

  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);
  const filteredArray: any = [];
  function closeModal() {
    setIsOpen(false);
  }

  const fetchRollingReserve = () => {
    dispatch(openLoader());
    client
      .get(`/settlement/${id}/rolling/reserves?currency=${currency}`)
      .then((res: any) => {
        setCustomerDetails(res.data);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };
  useEffect(() => {
    fetchRollingReserve();
  }, []);
  useEffect(() => {
    if (bearer) {
      fetchRollingReserve();
    }
  }, [bearer, currency]);

  const dataPaymentLinks = () => {
    const tempArr: RollingReserveData[] = [];
    customerDetails?.data?.items
      .reverse()
      .forEach((balance: any, index: number) => {
        tempArr.push({
          settlementDate: moment(balance?.settlementDate).format(
            "MMMM Do YYYY, h:mm a"
          ),
          dueDate: moment(balance?.settlementDate).format(
            "MMMM Do YYYY, h:mm a"
          ),
          settlementAmount: `${
            balance?.currency || currency
          } ${numberWithCommas(balance?.settlementAmount)}`,
          initialAmount: `${balance?.currency || currency} ${numberWithCommas(
            balance?.settlementAmount - balance?.withheldAmount
          )}`,
          withheldAmount: `${balance?.currency || currency} ${numberWithCommas(
            balance?.withheldAmount
          )}`,
          currency: balance?.currency || currency,
          id: balance?.id,
          status: (
            <StatusView
              status={balance?.status}
              green={"Completed"}
              red={"Due"}
            />
          ),
          balance: `${currency} ${numberWithCommas(balance?.balance)}`,
          // action: <BalanceMenu data={balance} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Rolling Reserve"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.page?.total}
        newButton={<div></div>}
        dropdown={dropdown}
        setDropdown={setDropdown}
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              applyAllHandler={applyAllHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />
      {/* <Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<CreatePaymentLink
					closeModal={closeModal}
					fetchPaymentLink={fetchBalanceHistory}
				/>
			</Modal> */}
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnRollingReserve ? ColumnRollingReserve : []}
          emptyPlaceHolder="You currently do not have any rolling reserve"
          total={customerDetails?.data?.page?.total}
          totalPage={customerDetails?.data?.page?.totalPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </Fragment>
  );
};

export default RollingReserve;
