import React, { useState, Fragment, useEffect } from "react";
import styles from "./Report.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ReportData, ColumnReport } from "../../../types/TablesTypes/Reports";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import Mocked from "./Mocked.json";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Single from "../Settlements/Single";
import Bulk from "../Settlements/Bulk";
import dayjs, { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const Report = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
  const [settlement, setSettlement] = useState<any>();
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];

  function closeModalSingle() {
    setIsOpenSingle(false);
  }
  function closeModalBulk() {
    setIsOpenBulk(false);
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Pending", value: "Pending" },
        { name: "Flagged", value: "Flagged" },
        { name: "Cancelled", value: "Cancelled" },
        { name: "Partial Completed", value: "Partial Completed" },
        { name: "Completed", value: "Completed" },
        { name: "Ongoing", value: "Ongoing" },
        { name: "Failed", value: "Failed" },
        { name: "Awaiting Disbursement", value: "Awaiting Disbursement" },
        { name: "Pending Disbursement", value: "Pending Disbursement" },
      ],
    },
  ];

  const fetchSettlement = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `settlement/all?subsidiaryname=${email}&settlementstatus=${status}&paymentreference=${paymentreference}&transactionreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setSettlement(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlement();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchSettlement();
    }
  }, [bearer, pageNumber, rowsPerPage, email]);

  useEffect(() => {
    setPageNumber(settlement?.currentPage || 1);
    setPreviousPage(settlement?.previousPage);
    setNextPage(settlement?.nextPage);
  }, [settlement]);

  const dataSettlement = () => {
    const tempArr: ReportData[] = [];
    settlement?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        tempArr.push({
          transactionReference: settlement.transactionReference,
          destination: settlement.destination,
          amount: settlement.transactionAmount,
          initiate: settlement.initiate,
          date: settlement.dateCreated,
          status: (
            <StatusView
              status={settlement?.settlementStatus}
              green="Completed"
              red="Failed"
              orange="Pending"
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataSettlement());
  }, [settlement?.items]);

  return (
    <div className={styles.dashbordContainer}>
      <TableHeader
        pageName="Report"
        data={settlement?.items}
        dataLength={settlement?.totalItems}
        value={email}
        setValue={setEmail}
        dropdown={dropdown}
        setDropdown={setDropdown}
        searchfn={true}
        placeHolder="Search by name"
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearAllHandler={clearHandler}
              applyAllHandler={applyAllHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnReport ? ColumnReport : []}
        value={value}
        emptyPlaceHolder={
          settlement?.totalItems == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={settlement?.totalItems}
        totalPage={settlement?.totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default Report;
