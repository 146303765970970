import React, { useState, Fragment, useEffect } from "react";
import styles from "./Fraudratio.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnFraudRatio,
  FraudRatioData,
} from "../../../types/TablesTypes/Fraudratio";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { Dayjs } from "dayjs";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import Mocked from "./fraudratio.json";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc, Divider } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { Grid } from "@material-ui/core";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const FraudRatio = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [fruadRatio, setFruadRatio] = useState<any>();
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];

  function closeModalSingle() {
    setIsOpenSingle(false);
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentmethod, setPaymentMethod] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentMethod("");
    setEmail("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Email Address",
      value: email,
      setValue: setEmail,
      type: "text",
    },
  ];

  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const fetchFraud = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `fraud/risk/fraud/ratios?merchantname=${paymentmethod}&merchantemail=${email}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
      );
      setFruadRatio(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchFraud();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchFraud();
    }
  }, [bearer, pageNumber, rowsPerPage, paymentmethod]);

  useEffect(() => {
    setPageNumber(fruadRatio?.current_page || 1);
    setPreviousPage(fruadRatio?.previous_page);
    setNextPage(fruadRatio?.next_page);
  }, [fruadRatio]);

  const dataTransaction = () => {
    const tempArr: FraudRatioData[] = [];
    fruadRatio?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          merchantName: transaction.subsidiaryName,
          ratio: transaction.fraud_ratio,
          cardType: transaction?.cardType,
          chargebackRatio: transaction?.chargebackRatio,
          status: (
            <StatusView
              status={transaction?.status}
              red="Lost"
              orange="New"
              green="Won"
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [fruadRatio?.data?.items]);

  const itemLength = fruadRatio?.length;

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
          ),
        },
      ]}
    />
  );
  // newButton={
  // 				<MenuFunc overlay={menu}>
  // 					<div className={styles.createLink}>Log Refund</div>
  // 				</MenuFunc>
  // 			}

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      <TableHeader
        pageName="fraud Ratio"
        data={fruadRatio?.data?.items}
        dataLength={fruadRatio?.data?.page?.total}
        value={paymentmethod}
        setValue={setPaymentMethod}
        dropdown={dropdown}
        setDropdown={setDropdown}
        exporting={false}
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              applyAllHandler={applyAllHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />
      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnFraudRatio ? ColumnFraudRatio : []}
          value={value}
          emptyPlaceHolder={
            fruadRatio?.data?.page?.total == 0
              ? "You currently do not have any data"
              : "Loading..."
          }
          total={fruadRatio?.data?.page?.total}
          totalPage={fruadRatio?.data?.page?.totalPage}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Fraud details</h3>
            <CloseIcon
              onClick={closeModalSingle}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Chargeback ratio</div>
                  <div className={styles.detailsKey}>0.0238</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Fraud ratio</div>
                  <div className={styles.detailsKey}>0.0127</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Risk status</div>
                  <div className={styles.detailsKey}>Low</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Status</div>
                  <div className={styles.detailsKey1}>
                    <StatusView
                      status="Successful"
                      red="Error"
                      orange="Pending"
                      green="Successful"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>
                    Chargeback threshold
                  </div>
                  <div className={styles.detailsKey}>NGN 64,982</div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>MasterCard</div>
                  <div className={styles.detailsKey}>NGN 75,000</div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>VISA</div>
                  <div className={styles.detailsKey}>NGN 75,000</div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Verve</div>
                  <div className={styles.detailsKey}>NGN 75,000</div>
                </div>
              </Grid>{" "}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FraudRatio;
