import React, { useState, useEffect, useRef } from 'react';
import styles from './personalInformation.module.scss';
import arrow from "../../../../assets/images/Icons/Icons/Stroke2.svg";
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Select, InputNumber } from 'antd';
import PlusIcon from '../../../../assets/images/Icons/Icons/uploadplus.svg';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../../redux/actions/loader/loaderActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../../constants/toastStyles';
import client, { formClient } from '../../../../api/client';
import { useDispatch } from 'react-redux';
import { serialize } from 'object-to-formdata';
import { convertMb } from '../../../../utils/convertMb';

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */
interface idType {
	id: number;
	name: string;
	countryId: number;
	bankCode: string;
	isMicrofinance: null | string;
	isMortgage: null | string;
}
interface initTypes {
	identification: any;
	passportphoto: any;
	proofofaddress: any;
}

const initProps: initTypes = {
	identification: '',
	passportphoto: '',
	proofofaddress: '',
};
function PersonalInformation({ subsidiaryId, cb }: any) {
	const history = useHistory();
	const [idType, setIdType] = useState<idType[]>([]);
	const [personalInfo, setPersonalInfo] = useState<any>({});
	const [files, setFiles] = useState<any>({});
	const [fileErrors, setFileErrors] = useState<any>({});

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const ref = useRef<any>(null);
	const refPassport = useRef<any>(null);
	const refProof = useRef<any>(null);

	const getIdType = () => {
		dispatch(openLoader());
		client.get(`/compliance/onboarding/identification/types`)
			.then((data: any) => {
				setIdType(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			});
	};
	const getPersonalInfo = () => {
		dispatch(openLoader());
		client.get(`/compliance/${subsidiaryId}/onboarding/personal/information/view`)
			.then((data: any) => {
				// console.log('dataf:', data?.data);
				setPersonalInfo(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			});
	};
	useEffect(() => {
		getIdType();
		getPersonalInfo();
	}, []);

	const filedDocument = () => {
		if ((files.identification, files.passportphoto, files.proofofaddress)) {
			return false;
		} else {
			return true;
		}
	};


	useEffect(() => {
		const dob = personalInfo?.dateOfBirth?.split('T')[0];
		const phone = personalInfo?.mobileNumber?.split('+234')[1];

		form.setFieldValue('bvn', personalInfo?.bvn);
		form.setFieldValue('firstname', personalInfo?.firstName);
		form.setFieldValue('lastname', personalInfo?.lastName);
		form.setFieldValue('gender', personalInfo?.gender);
		form.setFieldValue('dateofbirth', dob);
		form.setFieldValue('mobilenumber', phone);
		form.setFieldValue('idtype', personalInfo?.idType);
		form.setFieldValue('idnumber', personalInfo?.idNumber);
	}, [personalInfo]);

	const INITIALVALUES = {
		bvn: personalInfo?.bvn,
		firstname: personalInfo?.firstName,
		lastname: personalInfo?.lastName,
		gender: '',
		dateofbirth: '',
		mobilenumber: '',
		idtype: '',
		idnumber: '',
	};
	const updatedHandler = (values: any) => {
		// console.log({ values, files })
		// if (!files.proofofaddress) return setFileErrors({ ...fileErrors, proofofaddress: 'Proof of address is required' })
		// if (!files.identification) return setFileErrors({ ...fileErrors, identification: 'Identification file is required is required' })
		// if (!files.passportphoto) return setFileErrors({ ...fileErrors, passportphoto: 'Passport photograph is required' })
		dispatch(openLoader());
		const data = {
			...values,
			mobilenumber: `+234${values.mobilenumber}`,
			...files,
		};
		const formData = serialize(data);
		formClient
			.post(`/compliance/${subsidiaryId}/onboarding/personal/information`, formData)
			.then(async (res: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: res.data.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				cb?.();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const handleChange = (e: any) => {
		setFileErrors({ ...fileErrors, [e.target.name]: '' })
		setFiles((prevState: any) => {
			return {
				...prevState,
				[e.target.name]: e.target.files[0],
			};
		});
	};

	const removeFile = (field: any) => {
		setFiles((prevFiles: any) => ({
			...prevFiles,
			[field]: null // Set the file to null in state
		}
		));

		// Reset the file input field
		if (ref.current) {
			ref.current.value = ""; // This will allow you to upload the same file again after clearing
		}
	};


	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperInner}>
				<div className={styles.wrapperInnerRight}>
					<h2 className={styles.wrapperh2}>Primary Identity Information</h2>
					{/* <p className={styles.wrapperp}>
						This helps us confirm your identity. We use the details you provide
						for contact and verification purposes only and it will not be visible to any one
						else on your team/business.
					</p> */}
					<Form
						form={form}
						initialValues={INITIALVALUES}
						layout='vertical'
						name='nest-messages'
						onFinish={updatedHandler}
						validateMessages={validateMessages}>
						<div className={styles.inputwrapper}>
							<Form.Item
								style={{ flex: 1 }}
								name='bvn'
								label='BVN (To get your BVN, dial *565*0# on your registered mobile number'
								rules={[
									{
										required: true,
									},
									{ min: 11 },
									{ max: 11 },
									{
										validator: (_, value) =>
											/[0-9]/.test(value)
												? Promise.resolve()
												: Promise.reject('bvn should contain 11 number'),
									},
								]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
								/>
							</Form.Item>
							<div className={styles.flexInput}>
								<Form.Item
									style={{ flex: 1, marginRight: '16px' }}
									label='First name'
									name='firstname'
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'first name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
									/>
								</Form.Item>

								<Form.Item
									style={{ flex: 1 }}
									label='Last name'
									name='lastname'
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'Last name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
									/>
								</Form.Item>
							</div>
							<Form.Item
								style={{ flex: 1 }}
								name='gender'
								label='Gender'
								className='selectOptions'
								rules={[{ required: true }]}>
								<Select defaultValue='Male' placeholder='Gender' allowClear>
									<Option value='Male'>Male</Option>
									<Option value='Female'>Female</Option>
								</Select>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Date of Birth'
								name='dateofbirth'
								rules={[
									{ required: true },
									{
										validator: (_, value) =>
											/[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/.test(
												value
											)
												? Promise.resolve()
												: Promise.reject(
													'date must be in this YYYY-MM-DD format'
												),
									},
								]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
									placeholder='YYYY-MM-DD'
									type='date'
								/>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Phone number'
								name='mobilenumber'
								rules={[
									{
										required: true,
									},
									{ min: 10 },
									{ max: 10 },
									{
										validator: (_, value) =>
											/[0-9]/.test(value)
												? Promise.resolve()
												: Promise.reject('mobile number should contain number'),
									},
								]}>
								<Input
									style={{
										borderRadius: '7px',
										width: '100%',
										paddingTop: '10px',
									}}
									addonBefore='+234'
									className={styles.input}
									placeholder='8107061526'
								/>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								name='idtype'
								label='Identification document'
								className='selectOptions'
								rules={[{ required: true }]}>
								<Select allowClear>
									{idType.length &&
										idType?.map(({ name, id }, i) => (
											<Option key={i} value={id}>
												{name}
											</Option>
										))}
								</Select>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Identification number'
								name='idnumber'
								rules={[{ required: true }]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
								/>
							</Form.Item>{' '}
							<h3 className={styles.formH3}>
								Please upload any of the following means of identification:
								International Passport, Driver’s License, National ID or Voter’s
								ID. All files must be an image (.jpg, .jpeg, .png or .pdf) with
								max size of 5mb.
							</h3>

							{/* identification fields */}
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Identification
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.identification}</span>
								</h6>

								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => ref.current.click()}>
									<div className={styles.uploadBox}>
										{files?.identification ? (
											<div>
												{files && files.identification ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files?.identification)}
															className={styles.preview}
															alt='identification'
														/>
														{/* Delete Field Button */}
														<button
															onClick={(e) => {
																e.stopPropagation();  // Prevents click event from reaching the label
																removeFile('identification');
															}}
															className={styles.removeButton}
														>
															X
														</button>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.identification !== '' ? (
															<img
																src={personalInfo?.identification}
																className={styles.preview}
																alt='identification'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>

								<input
									type='file'
									id='identification'
									name='identification'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={ref}
									hidden
								/>

								{files?.identification?.size ? (
									convertMb(files?.identification?.size) > 5 ? (
										<p style={{ color: 'red', textAlign: 'center' }}>
											Document should be 5MB or less
										</p>
									) : (
										<p style={{ color: 'black', textAlign: 'center' }}>
											File size: {convertMb(files?.identification?.size)} MB
										</p>
									)
								) : null}

							</div>

							{/* Passports fields */}
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Passport photograph
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.passportphoto}</span>
								</h6>
								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => refPassport.current.click()}>
									<div className={styles.uploadBox}>
										{files?.passportphoto ? (
											<div>
												{files && files.passportphoto ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files.passportphoto)}
															className={styles.preview}
															alt='passportphoto'
														/>
														<button
															onClick={(e) => {
																e.stopPropagation();  // Prevents click event from reaching the label
																removeFile('passportphoto');
															}}
															className={styles.removeButton}
														>
															X
														</button>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.passportPhoto ? (
															<img
																src={personalInfo?.passportPhoto}
																className={styles.preview}
																alt='passportphoto'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>
								<input
									type='file'
									name='passportphoto'
									id='passportphoto'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={refPassport}
									hidden
								/>

								{files?.passportphoto?.size ? (
									convertMb(files?.passportphoto?.size) > 5 ? (
										<p style={{ color: 'red', textAlign: 'center' }}>
											Document should be 5MB or less
										</p>
									) : (
										<p style={{ color: 'black', textAlign: 'center' }}>
											File size: {convertMb(files?.passportphoto?.size)} MB
										</p>
									)
								) : null}

							</div>

							{/* Address fields */}
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Proof of Address
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.proofofaddress}</span>
								</h6>
								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => refProof.current.click()}>
									<div className={styles.uploadBox}>
										{files?.proofofaddress ? (
											<div>
												{files && files.proofofaddress ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files.proofofaddress)}
															className={styles.preview}
															alt='proofofaddress'
														/>
														<button
															onClick={(e) => {
																e.stopPropagation();
																removeFile('proofofaddress');
															}}
															className={styles.removeButton}
														>
															X
														</button>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.proofOfAddress ? (
															<img
																src={personalInfo?.proofOfAddress}
																className={styles.preview}
																alt='proofofaddress'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>
								<input
									type='file'
									id='proofofaddress'
									name='proofofaddress'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={refProof}
									hidden
								/>


								{files?.proofofaddress?.size ? (
									convertMb(files?.proofofaddress?.size) > 5 ? (
										<p style={{ color: 'red', textAlign: 'center' }}>
											Document should be 5MB or less
										</p>
									) : (
										<p style={{ color: 'black', textAlign: 'center' }}>
											File size: {convertMb(files?.proofofaddress?.size)} MB
										</p>
									)
								) : null}
							</div>

							<Divider />
							<div className={styles.buttonflex}>
								<button
									onClick={() => cb?.()}
									className={styles.cancel}>
									Cancel
								</button>
								<Form.Item shouldUpdate>
									{() => (
										<button type='submit' className={styles.submitHandler}>
											Save
										</button>
									)}
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</div >
		</div >
	);
}


export default PersonalInformation;
