import React, { Fragment, useState, useEffect } from "react";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { ColumnTransactionsPerBusiness } from "../../types/TablesTypes/Transactions";
import StatusView from "../../components/StatusView/StatusView";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import useApi from "../../hooks/useApi";
import transactionsApi from "../../api/transactions";
import moment from "moment";
import { numberWithCommas } from "../../utils/formatNumber";
import { Dayjs } from "dayjs";
import FilterModal from "../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../utils/datefunction";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../constants/date-event-filters";

interface IDtypes {
  id: number | null;
}

function Trans({ id }: IDtypes) {
  const [transactionsDetails, setTransactionDetails] = useState<any>([]);
  const [tableRow, setTableRow] = useState<any[]>();
  const gettransactionsApi = useApi(transactionsApi.getTransactions);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { currency } = useSelector((x) => x.appSettingsReducer);
  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const [customerDetails, setCustomerDetails] = useState<any>([]);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setName("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Email Address",
      value: email,
      setValue: setEmail,
      type: "text",
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Successful", value: "Successful" },
        { name: "Initiated", value: "Initiated" },
        { name: "Failed", value: "Failed" },
        { name: "Reversed", value: "Reversed" },
      ],
      type: "select",
    },
  ];
  // useEffect(() => {
  // 	dispatch(openLoader());
  // 	setIsLoading(true);
  // 	client
  // 		.get(
  // 			`order/all/paginated?subsidiaryid=${id}&status=${status}&currency=${currency}&customername=${name}&paymentreference=${paymentreference}&orderreference=${orderreference}&email=${email}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
  // 		)
  // 		.then((res: any) => {
  // 			// console.log("paginated", res?.data);
  // 			setTransactionDetails(res?.data?.data);
  // 			setIsLoading(false);
  // 			dispatch(closeLoader());
  // 			setBearer(false);
  // 		})
  // 		.catch((error) => {
  // 			dispatch(closeLoader());
  // 			setIsLoading(false);
  // 			if (error.response) {
  // 				const message = error?.response?.data?.message;
  // 				dispatch(
  // 					openToastAndSetContent({
  // 						toastStyles: ToastErrorStyles,
  // 						toastContent: message,
  // 					})
  // 				);
  // 			} else if (error.request) {
  // 				console.log(error.request);
  // 			} else {
  // 				console.log('Error', error.message);
  // 			}
  // 		});
  // }, [bearer, name, pageNumber, rowsPerPage, currency]);

  const fetchOrders = () => {
    dispatch(openLoader());
    setIsLoading(true);

    client
      .get(
        `order/all/paginated?subsidiaryid=${id}&status=${status}&currency=${currency}&customername=${name}&paymentreference=${paymentreference}&orderreference=${orderreference}&email=${email}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
      )
      .then((res: any) => {
        // Handle response
        setTransactionDetails(res?.data?.data);
        setIsLoading(false);
        dispatch(closeLoader());
        setBearer(false);
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.error("Request Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchOrders();
    }
  }, [bearer, name, pageNumber, rowsPerPage, currency]);

  useEffect(() => {
    setPageNumber(transactionsDetails?.page?.currentPage || 1);
    setPreviousPage(transactionsDetails?.page?.currentPage - 1);
    setNextPage(transactionsDetails?.page?.currentPage + 1);
  }, [transactionsDetails]);

  const dataTransactions = () => {
    const tempArr: any[] = [];
    transactionsDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((transactions: any, index: number) => {
        return tempArr.push({
          date: moment(transactions?.dateCreated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          merchantName: transactions?.customerName,
          email: transactions?.customerEmail,
          transactionAmount: `${transactions?.currency} ${numberWithCommas(
            transactions?.amountFormatted
          )}`,
          paymentChannel: transactions?.paymentTypeName || "NIL",
          // status: (
          //   <StatusView
          //     status={transactions?.orderStatus ?? ""}
          //     green="Successful"
          //     orange="Initiated"
          //     red="Failed"
          //     blue="Reversed"
          //   />
          // ),
          status: transactions?.orderStatus,
          orderReference: transactions?.orderReference,
          paymentReference: transactions?.paymentReference,
          externalReference: transactions?.externalReference,
          customerPhone: transactions?.customerPhone,
          customerFee: transactions?.customerFee,
          cardType: transactions?.cardType,
          fee: transactions?.fee,
          settlementAmount: transactions?.settlementAmount,
          customerName: transactions?.customerName,
          paymentResponseMessage: transactions?.paymentResponseMessage,
          customerEmail: transactions?.customerEmail,
          accountNumber: transactions?.accountNumber,
          ipAddress: transactions?.ipAddress,
          customFeeFormatted: transactions?.customFeeFormatted,
          finalSettlementDate: transactions?.finalSettlementDate,
          maskedPan: transactions?.maskedPan,
          amount: transactions?.amount,
          amountFormatted: transactions?.amountFormatted,
          currency: transactions?.currency,
          narration: transactions?.narration,
          orderPaymentId: transactions?.orderPaymentId,
          orderPaymentReference: transactions?.orderPaymentReference,
          orderStatus: transactions?.orderStatus,
          paymentResponseCode: transactions?.paymentResponseCode,
          paymentType: transactions?.paymentType,
          processorProviderBank: transactions?.processorProviderBank,
          remarks: transactions?.remarks,
          subsidiaryFee: transactions?.subsidiaryFee,
          subsidiaryName: transactions?.subsidiaryName,
          subsidiaryId: transactions?.subsidiaryId,
          whoBearsFee: transactions?.whoBearsFee,
          total: transactions?.total,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransactions);
  }, [transactionsDetails?.items, currency]);

  const itemLength = transactionsDetails?.items?.length;

  interface RowData {
    id: number;
    merchantName: string;
    date: string; // Adjust the type according to your actual data structure
  }

  const displayTransDetails = (row: RowData) => {
    setModalContent(row);
    setIsModalOpen(true);
    // console.log(transactionsDetails)
    // setCustomerDetails(transactionsDetails);
  };

  useEffect(() => {
    if (customerDetails && Array.isArray(customerDetails.items)) {
      // Extract IDs from items
      const ids = customerDetails.items.map((item: { id: any }) => item.id);
      //   console.log('Extracted IDs:', ids);
    } else {
      //   console.log('No items or invalid data structure');
    }
  }, [customerDetails]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <Fragment>
      <TableHeader
        pageName="Transactions"
        data={transactionsDetails?.items}
        dataLength={transactionsDetails?.total}
        value={name}
        setValue={setName}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search by customer name"
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />
      {gettransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          {/* {console.log(transactionsDetails)} */}
          <PaginationTable
            data={tableRow ? tableRow : []}
            columns={
              ColumnTransactionsPerBusiness ? ColumnTransactionsPerBusiness : []
            }
            emptyPlaceHolder={
              itemLength == 0
                ? "You currently do not have any transactions"
                : "Loading..."
            }
            value={value}
            total={transactionsDetails?.total}
            totalPage={transactionsDetails?.totalPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            clickAction={displayTransDetails}
            nextPage={nextPage}
            setNextPage={setNextPage}
            previousPage={previousPage}
            setPreviousPage={setPreviousPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            showModal={true} // Set to true if you want to show the modal
          />
        </>
      )}
    </Fragment>
  );
}

export default Trans;
