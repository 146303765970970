import React, { useState, useEffect } from 'react';
import styles from './Collection.module.scss';
import PaginationTable from '../../../components/table/pagination-table';
import {
	ColumnCollection,
	CollectionData,
} from '../../../types/TablesTypes/collection';
import StatusView from '../../../components/StatusView/StatusView';
import TableHeader from '../../../components/TableHeader/TableHeader';
import client from '../../../api/client';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import Filtered from '../../../components/filterComponent/Filtered';
import { menuContent } from '../../../utils/menuContent';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import Mark from '../../../assets/images/Icons/u_save.svg';
import { Divider } from 'antd';
import { Grid } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import SelectWrapper from '../../../components/Select';
import { CollectionLimitData, ColumnLimitCollection } from '../../../types/TablesTypes/collectionLimit';
import moment from 'moment';
import LimitModal from '../../../components/ModalContent/LimitModal/LimitModal';
import { numberWithCommas } from '../../../utils/formatNumber';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input':
		{
			textAlign: 'center',
			padding: '8.1px 14px',
		},
	},
	select: {
		'& .MuiOutlinedInput-root': {
			color: '#414141',
			fontFamily: 'Roboto',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '14px',
			lineHeight: '16px',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
			backgroundColor: '#ffffff',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: '#E0E0E0',
			backgroundColor: '#ffffff',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E0E0',
		},
	},
});

const Collection = ({ data }: any) => {
	const dispatch = useDispatch();
	const [status, setStatus] = useState<string>('');
	const [value, setValue] = useState('');
	const [tableRow, setTableRow] = useState<any[]>();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
		isOpened: false, data: null
	});

	const [collection, setCollection] = useState<any>({ items: data ?? [] });

	function closeModalSingle() {
		setIsOpenSingle({ isOpened: false, data: null });
	}

	function openModalSingle(data: any = null) {
		console.log({ data })
		setIsOpenSingle({ isOpened: true, data: data });
	}

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//for filtering
	const [valueChannel, setValueChannel] = useState('');
	const [valueStatus, setValueStatus] = useState('');
	const [dropdown, setDropdown] = useState(false);


	const fetchCollection = async () => {
		if (data) return;
		dispatch(openLoader());
		try {
			const res: any = await client.get(
				`/limits/collection?status=${status}&size=${rowsPerPage}&page=${pageNumber}`
			);
			setCollection(res.data.data);
			// console.log('rest:', res);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchCollection();
	}, [pageNumber, rowsPerPage]);

	useEffect(() => {
		setPageNumber(collection?.page || 1);
		setPreviousPage((collection?.page ?? 0) - 1);
		setNextPage((collection?.page) + 1);
	}, [collection]);

	const dataTransaction = () => {
		const tempArr: CollectionLimitData[] = [];
		collection?.items
			.reverse()
			.forEach((limit: any, index: number) => {
				tempArr.push({
					...limit,
					id: limit?.id,
					currency: limit?.currency?.shortName,
					value: (limit?.limitItem?.valueType || limit?.valueType == "decimal") ? numberWithCommas(limit?.value) : limit?.value,
					internationalValue: (limit?.limitItem?.valueType || limit?.valueType == "decimal") ? numberWithCommas(limit?.internationalValue) : limit?.internationalValue,
					valueType: limit?.limitItem?.valueType || limit?.valueType,
					limitGroupName: limit?.limitGroup?.name,
					limitItemName: limit?.limitItem?.name,
					isActive: limit?.isActive,
					dateCreated: moment(limit?.dateCreated).format('MMMM Do YYYY, h:mm a'),
				});
			});
		return tempArr;
	};

	// console.log({ collection })
	useEffect(() => {
		setTableRow(dataTransaction());
	}, [collection?.items]);

	return (
		<div style={{ marginTop: '38px' }}>
			<TableHeader
				pageName='collection'
				dataLength={collection?.total}
				data={collection?.items}
				value={value}
				setValue={setValue}
				exporting={false}
				filtering={false}
				searchfn={false}
				newButton={
					<div
						onClick={() => openModalSingle()}
						className={styles.createLink}>
						Add limit
					</div>
				}
				FilterComponent={
					<>
						<Filtered
							menuContent={menuContent}
							dropdown={dropdown}
							setDropdown={setDropdown}
							valueChannel={valueChannel}
							setValueChannel={setValueChannel}
							valueStatus={valueStatus}
							setValueStatus={setValueStatus}
							status={true}
							channel={true}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={ColumnLimitCollection ? ColumnLimitCollection : []}
				value={value}
				emptyPlaceHolder={
					collection?.total == 0 || collection?.items?.length == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={collection?.total}
				totalPage={collection?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				clickAction={(value: any) => { openModalSingle(value) }}
			/>

			{/* //MODAL FOR SINGLE */}
			<Modal
				isOpen={modalIsOpenSingle.isOpened}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<LimitModal data={modalIsOpenSingle.data} closeModal={closeModalSingle} cb={fetchCollection} />
			</Modal>
		</div>
	);
};

export default Collection;
