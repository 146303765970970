import React, { useState, Fragment, useEffect } from "react";
import styles from "./AudioTrail.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnAudioTrail,
  AudioTrailData,
} from "../../../types/TablesTypes/Audiotrail";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import Mocked from "./audiotrail.json";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc, Divider } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { Grid } from "@material-ui/core";
import Udownload from "../../../assets/images/Icons/u_download-alt.svg";
import { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const AudioTrail = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [audioTrail, setAudioTrail] = useState<any>();
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];

  function closeModalSingle() {
    setIsOpenSingle(false);
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentmethod, setPaymentMethod] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [status, setStatus] = useState("");
  const [merchantName, setMerchantName] = useState("");

  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentMethod("");
    setSubsidiary("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
    setMerchantName("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Method",
      value: paymentmethod,
      setValue: setPaymentMethod,
      type: "text",
    },
    {
      name: "subsidiary Id",
      value: subsidiary,
      setValue: setSubsidiary,
      type: "text",
    },
  ];

  const fetchAudioTrail = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `fraud/risk/audit/trails?merchantname=${merchantName}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
      );
      setAudioTrail(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchAudioTrail();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchAudioTrail();
    }
  }, [bearer, pageNumber, rowsPerPage, merchantName]);

  useEffect(() => {
    setPageNumber(audioTrail?.current_page || 1);
    setPreviousPage(audioTrail?.previous_page);
    setNextPage(audioTrail?.next_page);
  }, [audioTrail]);

  const dataTransaction = () => {
    const tempArr: AudioTrailData[] = [];
    audioTrail?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          user: transaction.user_name,
          activity: transaction.activity,
          date: transaction?.dateCreated,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [audioTrail?.data?.items]);

  const itemLength = audioTrail?.data?.items?.length;

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <p onClick={() => setIsOpenSingle(true)}>Log a single refund</p>
          ),
        },
      ]}
    />
  );
  // newButton={
  // 				<MenuFunc overlay={menu}>
  // 					<div className={styles.createLink}>Log Refund</div>
  // 				</MenuFunc>
  // 			}

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      <TableHeader
        pageName="Audio Trails"
        data={audioTrail?.data?.items}
        dataLength={audioTrail?.data?.page?.total}
        value={merchantName}
        setValue={setMerchantName}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search by Name"
        exporting={false}
        filtering={false}
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnAudioTrail ? ColumnAudioTrail : []}
        value={value}
        emptyPlaceHolder={
          audioTrail?.data?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={audioTrail?.data?.page?.total}
        totalPage={audioTrail?.data?.page?.total_page}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Activity details</h3>
            <CloseIcon
              onClick={closeModalSingle}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Date</div>
                  <div className={styles.detailsKey}>0.0238</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>IP Address</div>
                  <div className={styles.detailsKey}>0.0127</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>User</div>
                  <div className={styles.detailsKey}>Low</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Role</div>
                  <div className={styles.detailsKey1}>Lorem</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Email</div>
                  <div className={styles.detailsKey}>
                    moremi.adisa@gmail.com
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}></div>
                  <div className={styles.detailsKey}></div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>VISA</div>
                  <div className={styles.detailsKey}>
                    Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_0)
                    AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.88
                    Safari/537.36
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12} sm={6}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>VISA</div>
                  <div className={styles.detailsKey}>
                    User uploaded document moremi_adisa.pdf document
                  </div>
                </div>
              </Grid>{" "}
              <Grid item xs={12}>
                <button className={styles.Downloadbutton}>
                  <span className={styles.Downloadbutton_span}>
                    <img src={Udownload} alt="" />
                  </span>
                  Download information
                </button>
              </Grid>{" "}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AudioTrail;
