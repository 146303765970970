import React, { useState, useEffect } from "react";
import styles from "./PendingFees.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnFees, FeesData } from "../../../types/TablesTypes/fees";
import TableHeader from "../../../components/TableHeader/TableHeader";
import client from "../../../api/client";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Times from "../../../assets/images/Icons/u_multiply (2).svg";
import { useDispatch } from "react-redux";
import { ToastErrorStyles } from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import Mocked from "./fees.json";
import { Row, Col } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Mark from "../../../assets/images/Icons/u_save.svg";
import { Divider } from "antd";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { Dayjs } from "dayjs";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import * as Yup from "yup";
import FilterModal from "../../../components/filterConfig/FilterModal";
import MenuOption from "../../../components/MenuOption/MenuOption";
import Approved from "../Approved/Approved";
import Declined from "../Declined/Declined";
import { numberWithCommas } from "../../../utils/formatNumber";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const PendingFees = ({ title }: { title: string }) => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState<any>({});

  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalIsOpenApproved, setIsOpenApproved] = React.useState(false);
  const [modalIsOpenDeclined, setIsOpenDeclined] = React.useState(false);
  const [fees, setFees] = useState<any>(Mocked);
  interface initTypes {
    country: string;
    payment_type: string;
    percentage_value: string;
    flat_value: string;
    currency: string;
  }

  function closeModalSingle() {
    setIsOpenSingle(false);
  }
  function closeModalApproved() {
    setIsOpenApproved(false);
  }
  function closeModalDeclined() {
    setIsOpenDeclined(false);
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [businesstype, setBusinesstype] = useState("");

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setStatus("");
    setBusinesstype("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Business Type",
      value: businesstype,
      setValue: setBusinesstype,
      type: "select",
      selective: [
        { name: "Individual", value: "Individual" },
        { name: "Company", value: "Company" },
      ],
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Active", value: "Active" },
        { name: "Pending-Verification", value: "Pending-Verification" },
        { name: "New", value: "New" },
        { name: "Pending-Approval", value: "Pending-Approval" },
      ],
    },
  ];

  const handleFees = (details: any) => {
    setIsOpenSingle(true);
    setDetails(details);
  };

  const menuOptions = [
    {
      id: 1,
      name: () => {
        return "View fee details";
      },
      icon: ViewIcon,
      onclick: (details: any, e: any) => {
        setDetails(details);
        setIsOpenSingle(true);
      },
    },
  ];

  const fetchBusinesses = async () => {
    dispatch(openLoader());
    try {
      const res: any = await client.get(
        `/pricing/collection/drafts?status=${status}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setFees(res.data.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    } finally {
      closeModalApproved();
      closeModalDeclined();
      setIsOpenSingle(false);
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchBusinesses();
    }
  }, [bearer, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(fees?.page?.currentPage || 1);
    setPreviousPage(fees?.page?.previousPage);
    setNextPage(fees?.page?.nextPage);
  }, [fees]);

  const dataTransaction = () => {
    const tempArr: FeesData[] = [];
    fees?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          id: transaction.id,
          type: transaction.type,
          country: transaction.country,
          paymentOption: transaction.paymentOption,
          paymentOptionCode: transaction.paymentOptionCode,
          currency: transaction.currency,
          pricingGroup: transaction?.pricingGroup,
          flatValue: transaction?.flatValue,
          internationalFlatValue: transaction?.internationalFlatValue,
          pricingGroupId: transaction?.pricingGroupId,
          cap: numberWithCommas(transaction?.cap, 4),
          fee: numberWithCommas(transaction?.fee * 100, 4),
          internationalCap: numberWithCommas(transaction?.internationalCap, 4),
          internationalFee: numberWithCommas(
            transaction?.internationalFee * 100,
            4
          ),
          dateCreated: moment(transaction?.dateCreated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(transaction, e);
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name()}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>{option?.name()}</div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [fees?.items]);

  const itemLength = fees?.length;

  return (
    <div style={{ marginTop: "38px" }}>
      <TableHeader
        pageName={title}
        dataLength={fees?.page?.total}
        data={fees?.items}
        value={value}
        setValue={setValue}
        exporting={false}
        filtering={false}
        searchfn={false}
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnFees ? ColumnFees : []}
        value={value}
        emptyPlaceHolder={
          fees?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={fees?.page?.total}
        totalPage={fees?.page?.totalPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        clickAction={(details) => handleFees(details)}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalwrapper}>
          <div className={styles.modalhead}>
            <h3 className={styles.modalheadh3}>Pending approval</h3>
            <CloseIcon
              onClick={closeModalSingle}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Row gutter={[16, 24]}>
              <Col span={8}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Pending ID</div>
                  <div className={styles.detailsKey}>{details?.id}</div>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Date created</div>
                  <div className={styles.detailsKey}>
                    {" "}
                    {details?.dateCreated}
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.customerInfo}>
                  <div className={styles.detailsValue}>Type</div>
                  <div className={styles.detailsKey}>{details.type}</div>
                </div>
              </Col>
              <Col span={24}>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  rows={4}
                  cols={50}
                  style={{ width: "100%", padding: "5px 10px" }}
                  placeholder="Give your reason"
                />
              </Col>

              {/* <Col span={24}>
								<div className={styles.customerInfoSingle}>
									<Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
										<Row gutter={[16, 8]}>
											<Col span={24}>
												<Checkbox value='A'>
													International card transaction
												</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='B'>
													International account transaction
												</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='C'>Local card transaction</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='D'>Local account transaction</Checkbox>
											</Col>
											<Col span={24}>
												<Checkbox value='E'>USSD transaction</Checkbox>
											</Col>
										</Row>
									</Checkbox.Group>
								</div>
							</Col> */}
              {/* <Col span={12}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>
										Internet banking transaction cost
									</div>
									<div className={styles.detailsKey}>NGN 5,005.98</div>
								</div>
							</Col>
							<Col span={12}>
								<div className={styles.customerInfo}>
									<div className={styles.detailsValue}>
										QR code transaction cost
									</div>
									<div className={styles.detailsKey}>NGN 5,005.98</div>
								</div>
							</Col> */}
              <Col span={12}>
                <button
                  onClick={() => setIsOpenDeclined(true)}
                  className={styles.declinebutton}
                >
                  <div style={{ marginRight: "8px" }}>
                    <img src={Times} alt="chargeback" />
                  </div>
                  <div className={styles.ml}>Decline</div>
                </button>
              </Col>
              <Col span={12}>
                <button
                  onClick={() => setIsOpenApproved(true)}
                  className={styles.approvebutton}
                >
                  <div style={{ marginRight: "8px" }}>
                    <img src={Mark} alt="chargeback" />
                  </div>
                  <div className={styles.ml}>Approve</div>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* //MODAL FOR APPROVED */}
      <Modal
        isOpen={modalIsOpenApproved}
        onRequestClose={closeModalApproved}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Approved
          closeModal={closeModalApproved}
          reason={reason}
          details={details}
          identifier="fee"
          fn={fetchBusinesses}
        />
      </Modal>

      {/* //MODAL FOR DECLINED */}
      <Modal
        isOpen={modalIsOpenDeclined}
        onRequestClose={closeModalDeclined}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Approved
          closeModal={closeModalDeclined}
          reason={reason}
          details={details}
          identifier="fee"
          isdecline={true}
          fn={fetchBusinesses}
        />
      </Modal>
    </div>
  );
};

export default PendingFees;
