import React, { useState, Fragment, useEffect } from "react";
import styles from "./SettlementPS.module.scss";
import PaginationTable from "../../../../components/table/pagination-table";
import {
  ColumnSettlementsReconciliation,
  SettlementsReconciliationData,
} from "../../../../types/TablesTypes/Reconciliation";
import StatusView from "../../../../components/StatusView/StatusView";
import TableHeader from "../../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../../api/transactions";
import useApi from "../../../../hooks/useApi";
import client from "../../../../api/client";
import { numberWithCommas } from "../../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../../components/filterComponent/Filtered";
import { menuContent } from "../../../../utils/menuContent";
import Mocked from "./Mocked.json";
import { Menu, Dropdown as MenuFunc } from "antd";
import FilterModal from "../../../../components/filterConfig/FilterModal";
import dayjs, { Dayjs } from "dayjs";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../../utils/datefunction";
import FilterButtonNew from "../../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../../constants/date-event-filters";

const SettlementPS = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [settlement, setSettlement] = useState<any>();

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    // {
    // 	name: 'Status',
    // 	value: status,
    // 	setValue: setStatus,
    // 	type:'select',
    // 	selective: [
    // 		{ name: 'Successful',value:'Successful' },
    // 		{ name: 'Initiated',value:'Initiated'},
    // 		{ name: 'Failed',value:'Failed' },
    // 		{ name: 'Reversed', value:'Reversed' },
    // 	],
    // },
  ];

  const fetchSettlement = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `settlement/all?subsidiaryname=${email}&settlementstatus=Completed&paymentreference=${paymentreference}&transactionreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setSettlement(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlement();
  }, []);

  useEffect(() => {
    fetchSettlement();
  }, [bearer, pageNumber, rowsPerPage, email]);

  useEffect(() => {
    setPageNumber(settlement?.currentPage || 1);
    setPreviousPage(settlement?.previousPage);
    setNextPage(settlement?.nextPage);
  }, [settlement]);

  const dataSettlement = () => {
    const tempArr: SettlementsReconciliationData[] = [];
    settlement?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        tempArr.push({
          transactionId: settlement.paymentReference,
          currency: settlement.currency,
          amount: `NGN ${numberWithCommas(settlement?.transactionAmount)}`,
          reference: settlement.transactionReference,
          businessName: settlement.subsidiaryName,
          country: "NGN",
          date: settlement.dateCreated,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataSettlement());
  }, [settlement?.items]);

  const itemLength = settlement?.length;

  return (
    <div className={styles.dashbordContainer}>
      <TableHeader
        pageName="Reconciliation"
        data={settlement?.items}
        dataLength={settlement?.totalItems}
        value={email}
        setValue={setEmail}
        dropdown={dropdown}
        setDropdown={setDropdown}
        searchfn={true}
        placeHolder="Search by name"
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearAllHandler={clearHandler}
              applyAllHandler={applyAllHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={
          ColumnSettlementsReconciliation ? ColumnSettlementsReconciliation : []
        }
        value={value}
        emptyPlaceHolder={
          settlement?.totalItems == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={settlement?.totalItems}
        totalPage={settlement?.totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default SettlementPS;
