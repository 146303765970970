import React, { useRef, useState, useEffect } from "react";
import styles from "./BusinessRegistration.module.scss";
import arrow from "../../../../assets/images/Icons/Icons/Stroke2.svg";
import { useHistory } from "react-router-dom";
import { Divider, Form, Input, Select, Modal, Tooltip } from "antd";
import PlusIcon from "../../../../assets/images/Icons/Icons/uploadplus.svg";
import EditIcon from "../../../../assets/images/Icons/Icons/u_pen.svg";
import RemoveIcon from "../../../../assets/images/Icons/Icons/u_x.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/Icons/Icons/u_xxx.svg";
import { v4 as uuidv4 } from "uuid";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader,
} from "../../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../../constants/toastStyles";
import client, { formClient } from "../../../../api/client";
import { useDispatch } from "react-redux";
import { serialize } from "object-to-formdata";
import AddStakeholder from "./AddStakeholder";
import EditStakeholder from "./EditStakeholder";
import { convertMb } from "../../../../utils/convertMb";

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */
interface idType {
  id: number;
  name: string;
  countryId: number;
  bankCode: string;
  isMicrofinance: null | string;
  isMortgage: null | string;
}

interface stakeType {
  id: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  mobileNumber: string;
  bvn: string;
}
function BusinessRegistration({ subsidiaryId, cb }: any) {
  const history = useHistory();
  const [businessClass, setBusinessClass] = useState<idType[]>([]);
  const [expectedMonthlyTransactionVolume, setExpectedMonthlyTransactionVolume] = useState<idType[]>([]);
  const [files, setFiles] = useState<any>({});
  const [info, setInfo] = useState<any>();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const ref = useRef<any>(null);
  const refparticular = useRef<any>(null);
  const refStatusReport = useRef<any>(null);
  const refshares = useRef<any>(null);
  const refpoa = useRef<any>(null);
  const refdirector1 = useRef<any>(null);
  const reflicense = useRef<any>(null);
  const refmemo = useRef<any>(null);

  const [stakeHolder, setStakeHolder] = useState<stakeType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [editStake, setEditStake] = useState<stakeType>();

  const getIdType = () => {
    dispatch(closeLoader());
    client.get(`/compliance/business/classes`)
      .then((data: any) => {
        setBusinessClass(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data ?? {};
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }).finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    getIdType();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalEdit = () => {
    setIsModalEditOpen(true);
  };

  const handleEditOk = () => {
    setIsModalEditOpen(false);
  };

  const handleEditCancel = () => {
    setIsModalEditOpen(false);
  };
  const INITIALVALUES = {
    businessclassid: "",
    taxidnumber: "",
  };

  const isDocumentsOnFile = (documents: any) => {
    // console.log({ documents })
    const _info = documents?.registrationDocument;
    const _infoOthers: any[] = documents?.otherDocuments ?? [];
    const poa = _infoOthers.find(x => x.documentName == "ProofOfAddress")?.value;
    const directorId = _infoOthers.find(x => x.documentName == "DirectorIdentification")?.value;
    // const poa = _infoOthers.find(x => x.documentName =="DirectorIdentification");
    if (_info == null) return false;
    if (_info.certOfIncorporation
      && _info.memoOfAssociation
      && _info.taxIdNumber
      && _info.businessClassId > 0
      && directorId
      && poa
      && stakeHolder.length > 0) {
      // if ((_info.certOfIncorporation, _info.memoOfAssociation, _info.particularsofdirectors, _info.proofofaddress, _info.directorsidentification, stakeHolder.length > 0)) {
      return true;
    } else {
      return false;
    }
    return false;
  };

  const isFileFilled = () => {
    // console.log({ files });
    if (files.certofincorporation
      && files.memoofassociation
      && files.proofofaddress
      && files.directorsidentification
      && stakeHolder.length > 0
    ) return true;
    return false;
  };

  const isRequiredInputFilled = () => {
    console.log({ info })
    const bus = info?.registrationDocument ?? {}
    if (bus.businessClassId && bus.registrationNumber && bus.expectedMonthlyTransactionVolumn && bus.taxIdNumber) return true;
    return false;
  };
  const validateFormInput = () => {
    // console.log({ files, info })
    if (form.isFieldsTouched(true) || isFileFilled() || isDocumentsOnFile(info) || isRequiredInputFilled()) return false;
    // var errors = form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    // console.log({ errors })
    return true;
  };

  const handleChange = (e: any) => {
    setFiles((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.files[0],
      };
    });
  };

  // const handleFileChange = (e: any) => {
  //   setFiles((prevState: any) => {
  //     return {
  //       ...prevState,
  //       [e.target.name]: e.target.files,
  //     };
  //   });
  // };

  const handleFileChange = (e: any) => {
    const selectedFiles = Array.from(e.target.files); // this is to convert FileList to array
    setFiles((prevState: any) => {
      const existingFiles = prevState[e.target.name] || [];
      const updatedFiles = [...existingFiles, ...selectedFiles]; // this is to merge existing and new files
      return {
        ...prevState,
        [e.target.name]: updatedFiles,
      };
    });
    // Reset of file input to allow re-selecting files
    e.target.value = '';
  };

  // const showMultipleFiles = (name: string, files: any) => {
  //   console.log({ files })
  //   var fileList = [];
  //   var filess = files[name]
  //   for (let i = 0; i < filess.length; i++) {
  //     console.log(filess[i].name); // Logs each file's name
  //     const element = filess[i];
  //     fileList.push(<img
  //       src={URL.createObjectURL(element)}
  //       className={styles.preview}
  //       alt={name}
  //     />)
  //   }
  //   return fileList
  // }

  const showMultipleFiles = (name: string, files: any) => {
    const fileList = files[name] || [];
    return fileList.map((file: File, index: number) => (
      <div key={index} className={styles.previewwrap}>
        <img
          src={URL.createObjectURL(file)}
          className={styles.preview}
          alt={file.name}
        />
        {/* Individual Delete Button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            removeSpecificFile(name, index);
          }}
          className={styles.removeButton}
        >
          X
        </button>
      </div>
    ));
  };

  // const removeSpecificFile = (field: string, index: number) => {
  //   setFiles((prevFiles: any) => {
  //     const updatedFiles = prevFiles[field]?.filter((_: any, i: number) => i !== index) || [];
  //     return {
  //       ...prevFiles,
  //       [field]: updatedFiles, // Retain other files in the field
  //     };
  //   });
  // };

  const removeSpecificFile = (field: string, index: number) => {
    setFiles((prevFiles: any) => {
      const fieldFiles = prevFiles[field] || [];
      console.log('Original Files:', fieldFiles);

      const updatedFiles = fieldFiles.filter((_: any, i: any) => i !== index);
      console.log('Updated Files:', updatedFiles);

      return {
        ...prevFiles,
        [field]: updatedFiles.length > 0 ? updatedFiles : undefined,
      };
    });
  };

  const getFilesSize = (files: any): number => {
    console.log({ files })
    let fileSize = 0;
    for (let i = 0; i < files.length; i++) {
      console.log(files[i].size); // Logs each file's name
      fileSize += files[i].size
    }
    return fileSize;
  }

  const composeFiles = (formData: any, filess: any, payloadname: string) => {
    console.log(formData)
    for (let i = 0; i < filess?.length; i++) {
      console.log(filess[i]); // Logs each file's name
      formData.append(payloadname, filess[i])
    }
    return formData;
  }
  const updatedHandler = (values: any) => {
    dispatch(openLoader());
    const data = { ...values, ...files };
    console.log({ data })
    const formData: any = serialize(data);
    formData.particularsofdirectors = null;
    formData.directorsidentification = null;
    composeFiles(formData, data.particularsofdirectors, "ParticularsOfDirectors")
    composeFiles(formData, data.directorsidentification, "DirectorsIdentification")
    formClient
      .post(
        `/compliance/${subsidiaryId}/onboarding/business/registration/add-or-update`,
        formData
      )
      .then(async (res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        cb?.();
      })
      .catch((error: any) => {
        // console.log(error)
        dispatch(closeLoader());
        const { message } = error?.response?.data ?? { message: "Files sizes may be too large" };
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const getStake = () => {
    client
      .get(`/compliance/${subsidiaryId}/onboarding/stakeholder/view`)
      .then((data: any) => {
        setStakeHolder(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data ?? {};
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const expectedMonthlyTransactionVolumes = [
    { id: 1, name: "Less than ₦500,000", upperBound: 500000 },
    { id: 2, name: "₦500,000 - ₦1,000,000", lowerBound: 500000, upperBound: 1000000 },
    { id: 3, name: "₦1,000,000 - ₦5,000,000", lowerBound: 1000000, upperBound: 5000000 },
    { id: 4, name: "₦5,000,000 - ₦10,000,000", lowerBound: 5000000, upperBound: 10000000 },
    { id: 5, name: "₦10,000,000 - ₦20,000,000", lowerBound: 10000000, upperBound: 20000000 },
    { id: 6, name: "₦20,000,000 - ₦50,000,000", lowerBound: 20000000, upperBound: 50000000 },
    { id: 7, name: "₦50,000,000 - ₦100,000,000", lowerBound: 50000000, upperBound: 100000000 },
    { id: 8, name: "More than ₦100,000,000", lowerBound: 100000000, upperBound: 150000000 }
  ];

  const getInfo = () => {
    dispatch(openLoader());
    client
      .get(`/compliance/${subsidiaryId}/onboarding/business/registration/view`)
      .then((data: any) => {
        setInfo(data?.data?.data);
      })
      .catch((error: any) => {
        const { message } = error?.response?.data ?? { message: "Unable to get business registration documents" };
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }).finally(() => {
        dispatch(closeLoader());
      });
  };
  useEffect(() => {
    getStake();
    getInfo();
  }, []);

  const editHandler = (item: stakeType) => {
    setEditStake(item);
    showModalEdit();
  };

  const removeHandler = (id: number) => {
    client
      .delete(`/compliance/${subsidiaryId}/onboarding/stakeholder/delete/${id}`)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        getStake();
      })
      .catch((error: any) => {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  useEffect(() => {
    // const phone = businessInfo?.mobileNumber?.split("+234")[1];
    form.setFieldValue(
      "businessclassid",
      info?.registrationDocument?.businessClassId
    );
    form.setFieldValue(
      "taxidnumber",
      info?.registrationDocument?.taxIdNumber
    );
    form.setFieldValue(
      "registrationNumber",
      info?.registrationDocument?.registrationNumber
    );
    form.setFieldValue(
      "expectedMonthlyTransactionVolumn",
      info?.registrationDocument?.expectedMonthlyTransactionVolumn
    );
    // form.setFieldValue("address", businessInfo?.address);
    // form.setFieldValue("city", businessInfo?.city);
    // form.setFieldValue("state", businessInfo?.state);
    // form.setFieldValue("mobilenumber", phone);
    // form.setFieldValue("website", businessInfo?.website);
    // form.setFieldValue("facebook", businessInfo?.facebook);
    // form.setFieldValue("instagram", businessInfo?.instagram);
    // form.setFieldValue("twitter", businessInfo?.twitter);
  }, [info]);

  const removeFile = (field: any) => {
    setFiles((prevFiles: any) => ({
      ...prevFiles,
      [field]: null // Set the file to null in state
    }
    ));

    // Reset the file input field
    if (ref.current) {
      ref.current.value = ""; // This will allow you to upload the same file again after clearing
    }
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        {/* <div
          onClick={() => history.push("/dashboard/get-Started/onboarding")}
          className={styles.wrapperInnerLeft}
        >
          <img src={arrow} alt="" />
          <p>Get started</p>
        </div> */}
        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>Business Registration</h2>
          {/* <p className={styles.wrapperp}>
            We need this information to give us an idea of the kind of business
            you run, the type of product/services you will need to maximize this
            portal and how we cangenerally serve you better.
          </p> */}
          <Form
            form={form}
            initialValues={INITIALVALUES}
            layout="vertical"
            name="nest-messages"
            onFinish={updatedHandler}
            validateMessages={validateMessages}
          >
            <div className={styles.inputwrapper}>
              <Form.Item
                style={{ flex: 1 }}
                name="businessclassid"
                label="Business class"
                className="selectOptions"
                rules={[{ required: true }]}
              >
                <Select placeholder="Business class" allowClear>
                  {businessClass.length > 0 &&
                    businessClass?.map(({ name, id }, i) => (
                      <Option key={i} value={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="registrationNumber"
                label="Registration Number"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: "7px" }}
                  className={styles.input}
                  maxLength={20}
                  type="text"
                />
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="expectedMonthlyTransactionVolumn"
                label="Expected Monthly Transaction Volume (₦)"
                className="selectOptions"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Expected Monthly Transaction Volume (₦)"
                  allowClear
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {expectedMonthlyTransactionVolumes.length > 0 &&
                    expectedMonthlyTransactionVolumes.map(({ name, upperBound, lowerBound }, i) => (
                      <Option key={i} value={upperBound} data-upper-bound={upperBound} data-lower-bound={lowerBound}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ flex: 1 }}
                name="taxidnumber"
                label="Tax Identification Number"
                rules={[{ required: true }]}
              >
                <Input
                  style={{ borderRadius: "7px" }}
                  className={styles.input}
                  maxLength={20}
                  type="text"
                  placeholder="E.g 12345678-1234"
                />
              </Form.Item>


            </div>
            <div className={styles.inputwrapper}>
              <h3 className={styles.formH3}>
                All documents must be in any of the following: .jpeg, .jpg, .png pr .pdf
                format, with max size of 5mb
              </h3>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => ref.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: "20px" }}>
                      Certificate of incorporation*
                    </h1>
                    {files?.certofincorporation ? (
                      <div>
                        {convertMb(files?.certofincorporation?.size) > 5 ? (
                          <p style={{ color: "red" }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(files?.certofincorporation?.size)}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="certofincorporation"
                  id="certofincorporation"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={ref}
                  hidden
                />
                {files && files.certofincorporation && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.certofincorporation)}
                      className={styles.preview}
                      alt="certofincorporation"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('certofincorporation');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refmemo.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: "20px" }}>
                      Memorandum and articles of association*
                    </h1>
                    {files?.memoofassociation ? (
                      <div>
                        {convertMb(files?.memoofassociation?.size) > 5 ? (
                          <p style={{ color: "red" }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(files?.memoofassociation?.size)}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="memoofassociation"
                  id="memoofassociation"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refmemo}
                  hidden
                />
                {files && files.memoofassociation && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.memoofassociation)}
                      className={styles.preview}
                      alt="memoofassociation"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('memoofassociation');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <h6 className={styles.labelHeader}>
                  Particulars of Directors (Optional)
                </h6>

                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refparticular.current.click()}
                >
                  <div className={styles.uploadBox}>
                    {files?.particularsofdirectors ? (
                      <div>
                        {convertMb(getFilesSize(files?.particularsofdirectors)) > 10 ? (
                          <p style={{ color: "red" }}>
                            Document should be 10mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(getFilesSize(files?.particularsofdirectors))}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>
                        <span className={styles.uploadspan}>
                          <img src={PlusIcon} alt="" />
                        </span>
                        Select file
                      </p>
                    )}
                  </div>
                </label>
                <input
                  multiple={true}
                  type="file"
                  name="particularsofdirectors"
                  id="particularsofdirectors"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleFileChange}
                  ref={refparticular}
                  hidden
                />
                {files && files.particularsofdirectors && (
                  <div className={styles.previewwrap}>
                    {showMultipleFiles("particularsofdirectors", files)}
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('particularsofdirectors');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <h6 className={styles.labelHeader}>
                  Statement of return on allotment of shares (optional)
                </h6>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refshares.current.click()}
                >
                  <div className={styles.uploadBox}>
                    {files?.statementofshares ? (
                      <div>
                        {convertMb(files?.statementofshares?.size) > 5 ? (
                          <p style={{ color: "red" }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(files?.statementofshares?.size)}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>
                        <span className={styles.uploadspan}>
                          <img src={PlusIcon} alt="" />
                        </span>
                        Select file
                      </p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="statementofshares"
                  id="statementofshares"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refshares}
                  hidden
                />
                {files && files.statementofshares && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.statementofshares)}
                      className={styles.preview}
                      alt="statementofshares"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('statementofshares');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>

              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refpoa.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: "20px" }}>Proof of address*</h1>
                    {files?.proofofaddress ? (
                      <div>
                        {convertMb(files?.proofofaddress?.size) > 5 ? (
                          <p style={{ color: "red" }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(files?.proofofaddress?.size)}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="proofofaddress"
                  id="proofofaddress"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refpoa}
                  hidden
                />
                {files && files.proofofaddress && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.proofofaddress)}
                      className={styles.preview}
                      alt="proofofaddress"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('proofofaddress');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>

              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refStatusReport.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: "20px" }}>Status Report*</h1>
                    {files?.statusReport ? (
                      <div>
                        {convertMb(files?.statusReport?.size) > 5 ? (
                          <p style={{ color: "red" }}>
                            Document should be 5mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(files?.statusReport?.size)}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  name="statusReport"
                  id="statusReport"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={refStatusReport} // Use the unique ref here
                  hidden
                />
                {files && files.statusReport && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.statusReport)}
                      className={styles.preview}
                      alt="statusReport"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents click event from reaching the label
                        removeFile('statusReport');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <h3 className={styles.formH3}>
                Primary identification documents of all shareholders with 5%
                ownership and above (Upload all documents)
              </h3>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => refdirector1.current.click()}
                >
                  <div className={styles.uploadflex}>
                    <h1 style={{ paddingLeft: "20px" }}>Director 1*</h1>
                    {files?.directorsidentification ? (
                      <div>
                        {convertMb(getFilesSize(files?.directorsidentification)) > 10 ? (
                          <p style={{ color: "red" }}>
                            Document should be 10mb or less
                          </p>
                        ) : (
                          <p style={{ color: 'black' }}>File Size: {convertMb(getFilesSize(files?.directorsidentification))}Mb</p>
                        )}
                      </div>
                    ) : (
                      <p>Upload document</p>
                    )}
                  </div>
                </label>
                <input
                  multiple={true}
                  type="file"
                  name="directorsidentification"
                  id="directorsidentification"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleFileChange}
                  ref={refdirector1}
                  hidden
                />
                {files && files.directorsidentification && (
                  <div className={styles.previewwrap}>
                    {showMultipleFiles("directorsidentification", files)}
                    {/* Delete Field Button */}
                    {/* <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('directorsidentification');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button> */}
                  </div>
                )}
              </div>
              <div style={{ flex: 1, marginBottom: "30px", flexDirection: 'column', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <h6 className={styles.labelHeader}>
                  Operating license (optional)
                </h6>
                <label
                  style={{ cursor: "pointer", width: "100%" }}
                  onClick={() => reflicense.current.click()}
                >
                  <div className={styles.uploadBox}>
                    <p>
                      <span className={styles.uploadspan}>
                        <img src={PlusIcon} alt="" />
                      </span>
                      Select file{" "}
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="operatinglicense"
                  id="operatinglicense"
                  accept="image/png,image/jpg,image/jpeg, application/pdf"
                  onChange={handleChange}
                  ref={reflicense}
                  hidden
                />
                {files && files.operatinglicense && (
                  <div className={styles.previewwrap}>
                    <img
                      src={URL.createObjectURL(files.operatinglicense)}
                      className={styles.preview}
                      alt="operatinglicense"
                    />
                    {/* Delete Field Button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();  // Prevents click event from reaching the label
                        removeFile('operatinglicense');
                      }}
                      className={styles.removeButton}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.inputwrapper}>
              <h3 className={styles.formH3}>
                Shareholder/Trustee Details
              </h3>
              <p className={styles.numberP}>{stakeHolder?.length} added</p>
              <div className={styles.stakeholderbox}>
                {stakeHolder?.map((item) => (
                  <div className={styles.stakeholdersingle} key={item.id}>
                    <p>
                      {item.firstName} - {item.lastName}
                    </p>
                    <div className={styles.imageflex}>
                      <img
                        onClick={() => editHandler(item)}
                        src={EditIcon}
                        alt=""
                      />
                      <Divider type="vertical" />
                      <img
                        onClick={() => removeHandler(item.id)}
                        src={RemoveIcon}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>


              <div
                onClick={showModal}
                className={styles.stakeholderclick}
              >
                Add Shareholders / Trustee
              </div>
              <Divider />

              <div className={styles.buttonflex}>
                <div
                  onClick={() => cb?.()}
                  className={styles.cancel}
                >
                  Back
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <button
                      type="submit"
                      disabled={validateFormInput()}
                      className={styles.submitHandler}
                    >
                      Save
                    </button>
                  )}
                </Form.Item>
              </div>
            </div>
          </Form>


        </div>
      </div>
      <Modal
        title="Add Shareholders with 5% and above"
        open={isModalOpen}
        closeIcon={<CloseIcon />}
        closable={false}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <AddStakeholder getStake={getStake} handleCancel={handleCancel} />
      </Modal>

      {/* EDIT */}

      <Modal
        title="Edit Shareholders"
        open={isModalEditOpen}
        closeIcon={<CloseIcon />}
        closable={false}
        footer={null}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      >
        <EditStakeholder
          handleEditCancel={handleEditCancel}
          getStake={getStake}
          editStake={editStake}
        />
      </Modal>
    </div>
  );
}

export default BusinessRegistration;
