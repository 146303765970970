import React, { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnChargebacks,
  ChargebacksData,
} from "../../types/TablesTypes/Chargebacks";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import ChargebackMenu from "../../components/MenuOption/SettlementMenu/ChargebackMenu";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import moment from "moment";
import TransactionDetails from "./TransactionDetails/TransactionDetails";
import dayjs, { Dayjs } from "dayjs";
import FilterModal from "../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../utils/datefunction";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../constants/date-event-filters";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Successful" },
  { id: 3, name: "Failed" },
];

const Chargebacks = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [transactionsData, setTransactionsData] = useState<any>();
  const [chargeBackId, setChargeBackId] = useState<number | null>(null);
  const getTransactionsApi = useApi(transactionsApi.getTransactions);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "Successful", value: "Successful" },
        { name: "Pending", value: "Pending" },
        { name: "Failed", value: "Failed" },
      ],
      type: "select",
    },
  ];

  const fetchChargebacks = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `invoice/all/paginated?status=${status}&paymentreference=${paymentreference}&orderreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setTransactionsData(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchChargebacks();
  }, [bearer, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(transactionsData?.currentPage || 1);
    setPreviousPage(transactionsData?.previousPage);
    setNextPage(transactionsData?.nextPage);
  }, [transactionsData]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View invoice details";
      },
      icon: ViewIcon,
      onclick: (customerId: number) => {
        setChargeBackId(customerId);
      },
    },
  ];

  const dataChargebacks = () => {
    const tempArr: ChargebacksData[] = [];
    transactionsData?.items
      ?.slice(0)
      .reverse()
      .forEach((chargebacks: any, index: number) => {
        return tempArr.push({
          customerName: chargebacks?.customerName,
          emailAddress: chargebacks?.customerEmail,
          invoiceNumber: chargebacks?.reference,
          transactionAmount: chargebacks?.amount,
          dateIssued: moment(chargebacks?.dateCreated).format("DD, MMM YYYY"),
          dateDue: moment(chargebacks?.dueDate).format("DD, MMM YYYY"),
          status: (
            <StatusView
              status={chargebacks?.status}
              green="Successful"
              red="Failed"
              orange="Pending"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(chargebacks?.id);
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(chargebacks?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(chargebacks?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataChargebacks());
  }, [transactionsData?.items]);

  const itemLength = transactionsData?.items?.length;
  return (
    <div style={{ minHeight: "100vh" }}>
      {!chargeBackId ? (
        <>
          {" "}
          <TableHeader
            pageName="Invoices"
            data={transactionsData?.items}
            dataLength={transactionsData?.totalItems}
            value={paymentreference}
            setValue={setPaymentReference}
            dropdown={dropdown}
            setDropdown={setDropdown}
            searchfn={false}
            FilterComponent={
              <FilterButtonNew
                eventDate={eventDate}
                setEventDate={setEventDate}
                dropdown={dropdown}
                setDropdown={setDropdown}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
                setBearer={setBearer}
                clearHandler={clearHandler}
                clearAllHandler={clearHandler}
                filteredArray={filteredArray}
              />
            }
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <CreateCustomer
              fetchchargebacks={fetchChargebacks}
              closeModal={closeModal}
            />
          </Modal>
          {getTransactionsApi.loading ? (
            <div className={styles.loading} />
          ) : (
            <PaginationTable
              data={tableRow ? tableRow : []}
              columns={ColumnChargebacks ? ColumnChargebacks : []}
              value={value}
              emptyPlaceHolder={
                itemLength == 0
                  ? "You currently do not have any invoices"
                  : "Loading..."
              }
              total={transactionsData?.totalItems}
              totalPage={transactionsData?.totalPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              nextPage={nextPage}
              setNextPage={setNextPage}
              previousPage={previousPage}
              setPreviousPage={setPreviousPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </>
      ) : (
        <InvoiceDetails id={chargeBackId} setId={setChargeBackId} />
      )}
    </div>
  );
};

export default Chargebacks;
