import React from "react";
import styles from "./Statusview.module.scss";

type StatusProps = {
  status: string;
  green?: string;
  red?: string;
  orange?: string;
  purple?: string;
  blue?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
};
const StatusView = ({
  status,
  green,
  orange,
  red,
  purple,
  blue,
}: StatusProps) => {
  console.log({ status });

  return (
    <div>
      {red?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.declined}>{status}</div>
      ) : orange?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.processing}>{status}</div>
      ) : green?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.completed}>{status}</div>
      ) : purple?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.not}>{status}</div>
      ) : blue?.toLowerCase()?.includes(status?.toLowerCase()) ? (
        <div className={styles.new}>{status}</div>
      ) : (
        <div className={styles.declined}>{status}</div>
      )}
    </div>
    //     <div className={styles.wrapper}>
    // 	{status === red ? (<div className={styles.declined}>{red}</div>)
    // 	  : status === orange ? (<div className={styles.processing}>{orange}</div>)
    // 		: status === green ? (<div className={styles.completed}>{green}</div>)
    // 		  : status === purple ? (<div className={styles.purple}>{purple}</div>)
    // 			: status === blue ? (<div className={styles.review}>{blue}</div>)
    // 			  : (<div className={styles.processing}>{status}</div>)
    // 	}
    //   </div>
  );
};

export default StatusView;
