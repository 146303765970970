import React, { useState, Fragment, useEffect } from "react";
import styles from "./Channel.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnChannel, ChannelData } from "../../types/TablesTypes/Channels";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import businessApi from "../../api/businesses";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import moment from "moment";
import FilterModal from "../../components/filterConfig/FilterModal";
import dayjs, { Dayjs } from "dayjs";
import currencyApi from "../../api/currencies";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../utils/datefunction";
import ChannelDetails from "./ChannelDetails/ChannelDetails";
import AddChannel from "./AddChannel/AddChannel";
import { Select } from "@material-ui/core";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";

const tableStatus = [
  { id: 1, name: "New" },
  { id: 2, name: "Pending-Verification" },
];

interface detailProps {
  id: number;
  merchantid: string;
  channel: string;
  paymentmethod: string;
  rail: string;
  scheme: string;
  bank: string;
  currency: string;
  mid: string;
  username: string;
  password: string;
  url: string;
  createdat: string;
  status: string;
}

const Channel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [iden, setIden] = useState<string>("");

  const [businesses, setBusinesses] = useState<any>();
  const [businessDetail, setBusinessDetail] = useState<any>();
  const getBusinessApi = useApi(businessApi.getBusiness);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
    fetchBusinesses();
  }
  const getCurrenciesApi = useApi(currencyApi.getCurrencies);
  const getCountriesApi = useApi(currencyApi.getCountries);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
    getCountriesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data;
  const countries = getCountriesApi?.data?.data;

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [businessid, setBusinessid] = useState("");
  const [businesstype, setBusinesstype] = useState("");

  const clearHandler = () => {
    setCountry("");
    setStatus("");
    setBusinesstype("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Payment Method",
      value: businesstype,
      setValue: setBusinesstype,
      type: "select",
      selective: [
        {
          name: "Bank Transfer",
          value: "BT",
        },
        {
          name: "Card Payment",
          value: "C",
        },
        {
          name: "Bank Account",
          value: "BA",
        },
        {
          name: "USSD",
          value: "USSD",
        },
        {
          name: "Mobile Money",
          value: "MOMO",
        },
        {
          name: "Pay With Bank Transfer",
          value: "BANK-TRANSFER",
        },
        {
          name: "QR Code Payment",
          value: "NQR",
        },
      ],
    },
  ];
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchBusinesses = async () => {
    try {
      dispatch(openLoader());
      const res: any = await client.get(
        `payment/rails/all?status=${status}&id=${businessid}&paymentmethod=${businesstype}&limit=${rowsPerPage}&page=${pageNumber}&size=${rowsPerPage}`
      );
      if (res?.data?.statusCode == 404) {
        setBusinesses([]);
        setTableRow([]);
      } else {
        setBusinesses(res?.data);

        setPageNumber(res?.data?.data?.page?.page || 1);
      }
      setBearer(false);
      dispatch(closeLoader());
    } catch (error: any) {
      const { message } = error?.response?.data ?? { message: "" };
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };
  useEffect(() => {
    if (bearer) {
      fetchBusinesses();
    }
  }, [bearer, businesstype, pageNumber, rowsPerPage, businessid]);

  useEffect(() => {
    setBearer(true);
  }, [pageNumber, businesstype, rowsPerPage, businessid]);

  // useEffect(() => {
  // 	setPageNumber(businesses?.data?.page?.page || 1);
  // 	setPreviousPage((businesses?.data?.page?.page || 1) - 1);
  // 	setNextPage((businesses?.data?.page?.page || 1) + 1);
  // }, [businesses]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View details";
      },
      icon: ViewIcon,
      onclick: (business: detailProps) => {
        setId(business.id);
        setBusinessDetail(business);
      },
    },
    {
      id: 2,
      name: (id: number) => {
        return "Edit channel";
      },
      icon: ViewIcon,
      onclick: (business: detailProps) => {
        setBusinessDetail(business);
        setIsOpen(true);
        setIden("edit");
      },
    },
  ];

  const BusiComp = ({ logo, name }: { logo: string; name: string }) => {
    return (
      <div className={styles.busiCompWrapper}>
        <div className={styles.busiCompWrapperImg}>
          <img src={logo} alt="" />
        </div>
        <p className={styles.busiCompWrapperp}>{name}</p>
      </div>
    );
  };

  const dataBusinesses = () => {
    const tempArr: ChannelData[] = [];
    businesses?.data?.items?.forEach((business: any, index: number) => {
      return tempArr.push({
        id: business.id,
        merchantId: business?.coreMerchant?.merchantId,
        payment: business?.paymentmethod,
        currency: business?.currency,
        cardtype: business?.scheme,
        merchantName: business?.coreMerchant?.tradingName,
        channel: business?.bank,
        bank: business?.bank,
        rail: business?.rail,
        channelmid: business?.mid,
        status: (
          <StatusView
            status={business?.status ?? "ACTIVE"}
            orange="INACTIVE"
            green="ACTIVE"
          />
        ),
        action: (
          <MenuOption
            menuContent={
              <div>
                {menuOptions.map((option: any) => (
                  <div
                    key={option?.id}
                    onClick={(e) => {
                      option?.onclick(business, e);
                    }}
                    className={styles.menuContent}
                  >
                    <img
                      src={option?.icon}
                      alt={option?.name(business?.id)}
                      width={24}
                      height={24}
                    />
                    <div className={styles.optionName}>
                      {option?.name(business?.status)}
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        ),
      });
    });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataBusinesses());
  }, [businesses?.data]);

  const addHandler = () => {
    setIden("add");
    setIsOpen(true);
  };

  console.log({ businessid });

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      {!id ? (
        <>
          <TableHeader
            pageName="Channels"
            data={businesses?.data?.items}
            dataLength={businesses?._metadata?.totalcount}
            value={businessid}
            setValue={setBusinessid}
            dropdown={dropdown}
            setDropdown={setDropdown}
            placeHolder="Search by id"
            newButton={
              <div onClick={addHandler} className={styles.createLink}>
                Add channel
              </div>
            }
            FilterComponent={
              <FilterButtonNew
                dropdown={dropdown}
                setDropdown={setDropdown}
                setBearer={setBearer}
                applyAllHandler={applyAllHandler}
                clearAllHandler={clearHandler}
                filteredArray={filteredArray}
              />
            }
          />

          {getBusinessApi.loading ? (
            <div className={styles.loading} />
          ) : (
            <PaginationTable
              data={tableRow ? tableRow : []}
              columns={ColumnChannel ? ColumnChannel : []}
              emptyPlaceHolder={
                // businesses?.pageInfo?.totalCount == 0
                !businesses ||
                businesses.data?.length === 0 ||
                !businesses.pageInfo?.totalCount
                  ? "You currently do not have any data"
                  : "Loading..."
              }
              value={value}
              total={businesses?.data?.page?.total ?? 0}
              totalPage={businesses?.data?.page?.totalPage ?? 0}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              nextPage={nextPage}
              setNextPage={setNextPage}
              previousPage={previousPage}
              setPreviousPage={setPreviousPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
          {/* //MODAL FOR ADD */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <AddChannel
              closeModal={closeModal}
              identifier={iden}
              setIden={setIden}
              data={businessDetail}
            />
          </Modal>
        </>
      ) : (
        <ChannelDetails setId={setId} customerDetails={businessDetail} />
      )}
    </div>
  );
};

export default Channel;
