import React, { useState, useEffect } from "react";
import styles from "./PendingsettlementApproval.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnPendingSettlement,
  PendingSettlementData,
} from "../../../types/TablesTypes/PendingSettlementApproval";
import TableHeader from "../../../components/TableHeader/TableHeader";
import client from "../../../api/client";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import settlements from "../../../api/settlements";
import useApi from "../../../hooks/useApi";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const PendingSettlementApproval = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
  const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);
  const [settlement, setSettlement] = useState<any>();
  const settlementApi = useApi(settlements.getApprovedSettlements);
  // const [apiObject, setApiObject] = useState(null);

  // const { keyB: trxRef } = apiObject?.keyA || [{

  // }];

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Successful", value: "Successful" },
        { name: "Initiated", value: "Initiated" },
        { name: "Failed", value: "Failed" },
        { name: "Reversed", value: "Reversed" },
      ],
    },
  ];

  const fetchSettlement = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      settlementApi.request(
        `subsidiaryname=${email}&settlementstatus=${status}&paymentreference=${paymentreference}&transactionreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&size=${rowsPerPage}&page=${pageNumber}`
      );
      // setSettlement(data);
      // dispatch(closeLoader());
      // setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data || {};
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  const triggerSettlement = async (iden: boolean, id: string) => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(`settlement/log/approve`, {
        payoutRequests: [
          {
            id,
            isApproved: iden,
          },
        ],
      });
      fetchSettlement();

      dispatch(
        openToastAndSetContent({
          toastContent: data?.data?.message,
          toastStyles: ToastSuccessStyles,
        })
      );
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlement();
  }, [bearer, pageNumber, rowsPerPage, email]);

  useEffect(() => {
    setPageNumber(settlementApi?.data?.page || 1);
    setPreviousPage(settlementApi?.data?.page - 1);
    setNextPage(settlementApi?.data?.page + 1);
    setSettlement(settlementApi);
  }, [settlementApi?.data]);

  const dataTransaction = () => {
    const tempArr: PendingSettlementData[] = [];
    // console.log({ settlement });
    settlement?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          amount: transaction.amount,
          narration: transaction.narration,
          accountNumber: transaction.accountNumber,
          initiate: transaction.initiator,
          reference: transaction.payoutReference,
          bankCode: transaction.bankCode,
          action: (
            <div className={styles.buttonWrapper}>
              <button
                onClick={() => triggerSettlement(true, transaction.id)}
                className={styles.approve}
              >
                approve
              </button>
              <button
                onClick={() => triggerSettlement(false, transaction.id)}
                className={styles.disapprove}
              >
                decline
              </button>
            </div>
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [settlement?.data]);

  return (
    <div className={styles.dashbordContainer}>
      <TableHeader
        pageName="Pending Settlement"
        data={settlement?.data?.items}
        dataLength={settlement?.data?.page?.total}
        value={email}
        setValue={setEmail}
        dropdown={dropdown}
        setDropdown={setDropdown}
        searchfn={true}
        placeHolder="Search by name"
        FilterComponent={
          <>
            <FilterButtonNew
              eventDate={eventDate}
              setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              clearHandler={clearHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnPendingSettlement ? ColumnPendingSettlement : []}
        value={value}
        emptyPlaceHolder={
          settlement?.data?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={settlement?.data?.page?.total}
        totalPage={settlement?.data?.page?.totalPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default PendingSettlementApproval;
