import React, { useState, Fragment, useEffect } from "react";
import styles from "./ChargebackBreakdown.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnChargebackBreakdown,
  ChargebackBreakdownData,
} from "../../../types/TablesTypes/ChargebackBreakdown";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Filtered from "../../../components/filterComponent/Filtered";
import { menuContent } from "../../../utils/menuContent";
import Mocked from "./chargebackBreakdown.json";
import RefundsMenu from "../../../components/MenuOption/RefundsMenu/RefundsMenu";
import { Menu, Dropdown as MenuFunc } from "antd";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Upload from "./Upload";
import { Dayjs } from "dayjs";
import FilterModal from "../../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const ChargebackBreakdown = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [chargeback, setChargeback] = useState<any>();

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentmethod, setPaymentMethod] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentMethod("");
    setSubsidiary("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Payment Method",
      value: paymentmethod,
      setValue: setPaymentMethod,
      type: "text",
    },
    {
      name: "subsidiary Id",
      value: subsidiary,
      setValue: setSubsidiary,
      type: "text",
    },
  ];

  const fetchChargeback = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `fraud/risk/chargeback/summary?paymentmethod=${paymentmethod}&subsidiaryid=${subsidiary}&fromdate=${fromDate}&todate=${toDate}&size=${rowsPerPage}&page=${pageNumber}`
      );
      setChargeback(data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchChargeback();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchChargeback();
    }
  }, [bearer, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(chargeback?.current_page || 1);
    setPreviousPage(chargeback?.previous_page);
    setNextPage(chargeback?.next_page);
  }, [chargeback]);

  const dataTransaction = () => {
    const tempArr: ChargebackBreakdownData[] = [];
    chargeback?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          acquirer: transaction.acquirer,
          merchantName: transaction.subsidiaryName,
          rrn: transaction.rrn,
          transactionReference: transaction?.transactionReference,
          amount: `NGN${transaction?.value}`,
          // action: <RefundsMenu customerId={transaction.customer_id} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [chargeback?.data?.items]);

  useEffect(() => {
    setPageNumber(chargeback?.data?.page?.current_page || 1);
    setPreviousPage(chargeback?.data?.page?.previous_page);
    setNextPage(chargeback?.data?.page?.next_page);
  }, [chargeback?.data]);

  const itemLength = chargeback?.data?.items.length;

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      <TableHeader
        pageName="All Sales"
        data={chargeback?.data?.items}
        dataLength={chargeback?.data?.page?.total}
        value={subsidiary}
        setValue={setSubsidiary}
        dropdown={dropdown}
        setDropdown={setDropdown}
        exporting={false}
        filtering={false}
        searchfn={false}
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnChargebackBreakdown ? ColumnChargebackBreakdown : []}
        value={value}
        emptyPlaceHolder={
          chargeback?.data?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={chargeback?.data?.page?.total}
        totalPage={chargeback?.data?.page?.total_page}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default ChargebackBreakdown;
