import React, { useEffect, useState } from "react";
import Settlements from "../Settlements";
import styles from "./SettlementTransactions.module.scss";
import { Divider } from "antd";
import {
  closeLoader,
  openLoader,
} from "../../../../redux/actions/loader/loaderActions";
import client from "../../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../../constants/toastStyles";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../../utils/datefunction";
import { ReactComponent as CloseIcon } from "../../../../assets/images/Icons/close.svg";
import dayjs, { Dayjs } from "dayjs";
import useApi from "../../../../hooks/useApi";
import settlements from "../../../../api/settlements";
import moment from "moment";
import AllTransactions from "../../../Transactions/AllTransactions";
import { numberWithCommas } from "../../../../utils/formatNumber";
import { dateEventFilters } from "../../../../constants/date-event-filters";

function SettlementTransactions({ closeModal, settlementType, data, cb }: any) {
  const dispatch = useDispatch();
  const [settlement, setSettlement] = useState<any>();
  const allSettlementApi = useApi(settlements.getSettlementTransactions);
  const transactions = allSettlementApi?.data?.data || {};
  console.log({ transactions });

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [payment, setPayment] = useState("");
  const [transaction, setTransaction] = useState("");
  const [email, setEmail] = useState("");

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setStatus("");
    setPayment("");
    setTransaction("");
    setEmail("");
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray = [
    {
      name: "Payment Reference",
      value: payment,
      setValue: setPayment,
    },
    {
      name: "Transaction Reference",
      value: transaction,
      setValue: setTransaction,
    },
    {
      name: "Email Address",
      value: email,
      setValue: setEmail,
    },
  ];

  const fetchSettlement = async (isExport = false) => {
    dispatch(openLoader());
    try {
      const fromdate =
        moment(data?.dateCreated).format("yyyy-MM-DD") + " 00:00:00";
      const todate =
        moment(data?.dateCreated).format("yyyy-MM-DD") + " 23:59:59";
      const todaydate = moment(new Date()).format("yyyy-MM-DD") + " 23:59:59";
      let query = `subsidiaryId=${data?.merchantId}&status=${data?._status}&currency=${data?.currency}&fromdate=${fromdate}&todate=${todate}&size=${rowsPerPage}&page=${pageNumber}&export=${isExport}`;
      if (settlementType)
        query = `settlementId=${data?.id}&fromdate=2024-05-01&todate=${todaydate}&currency=${data?.currency}&export=${isExport}`;
      var res: any = await allSettlementApi.request(query);
      return res?.items;
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  const fetchSettlementCB = async (isExport = false) => {
    !isExport && closeModal && closeModal();
    return await fetchSettlement(isExport);
  };

  const submitSettlement = async () => {
    try {
      const confirm = window.confirm(
        "This settlement will be submitted to be reviewed for approval! Continue ?"
      );
      if (!confirm) return;
      const fromdate =
        moment(data?.dateCreated).format("yyy-MM-DD") + " 00:00:00";
      const todate =
        moment(data?.dateCreated).format("yyy-MM-DD") + " 23:59:59";
      dispatch(openLoader());
      settlements
        .processSettlement({
          status: data?._status,
          currency: data?.currency,
          fromdate: fromdate,
          todate: todate,
          subsidiaryId: data?.merchantId,
        })
        .then((d: any) => {
          dispatch(
            openToastAndSetContent({
              toastContent: d?.data?.message,
              toastStyles: ToastSuccessStyles,
            })
          );
        })
        .catch((error) => {
          const { message } = error.response.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        })
        .finally(() => {
          dispatch(closeLoader());
        });
      // setSettlement(res.data.data);
      // setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  console.log({ data });
  const processSettlement = async () => {
    try {
      const confirm = window.confirm(
        "This settlement will be processed right way! Continue ?"
      );
      if (!confirm) return;
      dispatch(openLoader());
      settlements
        .processSettlementApproved(data?.id)
        .then((d: any) => {
          dispatch(
            openToastAndSetContent({
              toastContent: d?.data?.message,
              toastStyles: ToastSuccessStyles,
            })
          );
        })
        .catch((error) => {
          const { message } = error.response.data;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        })
        .finally(() => {
          dispatch(closeLoader());
        });
      // setSettlement(res.data.data);
      // setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlement();
  }, [bearer, pageNumber, rowsPerPage, status]);

  useEffect(() => {
    setPageNumber(settlement?.page || 1);
    setPreviousPage(settlement?.page - 1);
    setNextPage(settlement?.page + 1);
  }, [settlement]);
  return (
    <div className={styles.tabContainer}>
      <div className={styles.tabContainerAction}>
        <div>
          Total Settlement Amount (
          {data?.transactionCount ?? transactions?.items?.length}):{" "}
          {`${data?.amount}`}
        </div>
        {data?._status?.toLowerCase() == "pending" && !settlementType && (
          <button
            onClick={() => submitSettlement()}
            className={styles.downloadbutton}
          >
            Submit Settlement
          </button>
        )}
        {data?._status?.toLowerCase() == "pending" &&
          settlementType == "smt-pending-process" && (
            <button
              onClick={() => processSettlement()}
              className={styles.downloadbutton}
            >
              Process Settlement
            </button>
          )}
      </div>
      <AllTransactions
        etransactions={transactions}
        cb={fetchSettlementCB}
        showDateFilter={false}
      />
    </div>
  );
}

export default SettlementTransactions;
