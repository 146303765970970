import React, { useState, Fragment } from "react";
import styles from "./ApproveRefundMenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import {useDispatch } from "react-redux";
import { Modal } from 'antd';
import { customStyles } from "../../../constants/modalStyles";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";
import StatusView from "../../StatusView/StatusView";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles, ToastSuccessStyles } from "../../../constants/toastStyles";
import PayoutVerificationModalSecondLevel from "../../ModalContent/PayoutVerificationModalSecondLevel/PayoutVerificationModalSecondLevel";
import OutlineTextInput from "../../input/OutlineTextInput";

type MenuProps = {
  data: any;
  cb?: Function | null | undefined;
};
interface initTypes {
  otp: string | undefined;
  batchId: number | undefined;
  subsidiaryId: string | undefined;
}

const initProps: initTypes = {
  otp: '',
  subsidiaryId: undefined,
  batchId: undefined,
};

const ApproveRefundMenu = ({ data, cb }: MenuProps) => {
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [verifyPayoutModalIsOpen, setVerifyPayoutModalIsOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState(initProps);

  function closeModal() {
    setIsOpen(false);
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };

  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const ApproveRefund = async () => {
    dispatch(openLoader());
    try {
      const refundIds = data;
      const res: any = await client.post('/refund/approve', {refundIds});
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      setVerifyPayoutModalIsOpen(true);
      closeMenu();
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const closeMenu = () => {
    setMenuOption(false);
  };

  const ConfirmRefund = async (isApproved : null | boolean) => {
    dispatch(openLoader());
    try {
      const res: any = await client.post('/refund/confirm', {
        otp: inputs.otp,
        refundIds: data,
        isApproved
      });
      const message = res?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      closeModal && closeModal();
    } catch (error: any) {
      console.error('Error Response:', error); // Log the full error response for debugging
      const  message  = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
    finally {
      dispatch(closeLoader());
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    ConfirmRefund(isApproved);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <div className={styles.approveDeclineContainer}>
                <button className={styles.approve} onClick={() => {ApproveRefund(); setIsApproved(true)}} > Approve </button>
                <button className={styles.decline}  onClick={() => {ApproveRefund(); setIsApproved(false)}} > Decline </button>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>

			<Modal
				open={verifyPayoutModalIsOpen}
				onCancel={() => setVerifyPayoutModalIsOpen(false)}
				centered
				footer={null}
			>
          <div className={styles.modalBody}>
            <div className={styles.businessForm}>
              <form onSubmit={handleSubmit} className={styles.form}>
                <p style={{margin:'20px 0px'}}>{data?.message || "Enter the One-Time Password (OTP) sent to your email"}</p>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="otp"
                  inputLabel="One-Time Password (OTP)"
                  inputValue={inputs.otp}
                />
              </form>
            </div>
            <div style={{margin:'20px 0px'}} className={styles.modalFooter}>
              {inputs.otp ? (
                <div className={styles.verify} onClick={handleSubmit}>
                  Verify Action
                </div>
              ) : (
                <div className={styles.notClickable}>Verify Action</div>
              )}
            </div>
          </div>
      </Modal>

    </Fragment>
  );
};

export default ApproveRefundMenu;
