import React, { useState, useEffect } from 'react';
import styles from './Business.module.scss';
import { useParams, useHistory } from 'react-router';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import StatusView from '../../../components/StatusView/StatusView';
import { ReactComponent as Exclaim } from '../../../assets/images/Icons/u_exclaim.svg';
import Checkbox from '@mui/material/Checkbox';
import ViewFile from './ViewFile';
import Verify from './Verify';
import Modal from 'react-modal';
import { computeCustomStyles, customStyles } from '../../../constants/modalStyles';
import UpdateDetailModal from './UpdateDetailModal';
import SubsidiaryConfigSection from './SubsidiaryConfigSection';
import UpdateSubsidiaryConfigModal from '../../../components/ModalContent/UpdateSubsidiaryConfigModal/UpdateSubsidiaryConfigModal';
import useApi from '../../../hooks/useApi';
import transactionsApi from '../../../api/subsidiaries';
import { numberWithCommas } from '../../../utils/formatNumber';
import SubsidiaryPreferenceSection from './SubsidiaryPreferenceSection';
import UploadAdditionalDocument from './UploadAdditionalDocument';
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
import SubsidiaryAdditionalPreferenceSection from '../SubsidiaryAdditionalPreferenceSection'
import client from '../../../api/client';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';

const BusinessDetails = () => {
	// const [businessDetails, setBusinessDetails] = useState<any>([]);
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [documentModalIsOpenSingle, setdocumentModalIsOpenSingle] = React.useState(false);
	const [openCustomizeConfigModal, setOpenCustomizeConfigModal] = React.useState<any>(false)
	const [accountManagers, setAccountManagers] = React.useState<any>(false)
	const [openDetailsUpdateModal, setOpenDetailsUpdateModal] = React.useState<any>({
		isOpen: false,
		useFullScreen: false,
		fieldName: '',
		currentValue: '',
	});
	function closeModalSingle() {
		setIsOpenSingle(false);
	}

	function closeDocumentModalSingle() {
		setdocumentModalIsOpenSingle(false);
	}

	const fetchSubsidiaryConfigs = useApi(transactionsApi.getSubsidiaryCustomizeConfig)
	const fetchSubdiaryDetails = useApi(transactionsApi.getSubsidiaryDetails)
	const groupConfigInformation: any = fetchSubsidiaryConfigs?.data?.data || {}
	const businessDetails = fetchSubdiaryDetails?.data?.data ?? {}
	// const fetchSubdiaryFiles = useApi(transactionsApi.getSubsidiaryFiles)
	// const subsidiaryFiles = fetchSubdiaryFiles?.data?. data ?? {}

	function closeDetailsUpdateModal(message?: string, err?: string) {
		setOpenDetailsUpdateModal({ isOpen: false, fieldName: '' });
		if (message) {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				}))
			fetchDetails();
		}
		if (err) {
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: err,
				}))
		}

	}
	const dispatch = useDispatch();
	const history = useHistory();
	const [list, setList] = useState({
		firstList: true,
		secondList: false,
		thirdList: false,
		fourthList: false,
		fifthList: false,
		sixthList: false,
		seventhList: false,
		eightList: false,
		ninthList: false, //note this is meant for the stakeholders
		subPreference: false,
		additionalsubPreference: false,
		complianceUploadDocs: false
	});


	const fetchDetails = () => {
		id && fetchSubdiaryDetails.request(id);
		id && fetchSubsidiaryConfigs.request(id);
		// id && fetchSubdiaryFiles.request(id); 
	};

	useEffect(() => {
		fetchDetails();
	}, [id]);

	// const downloadMerchantFiles = async () => {
	// 	if (subsidiaryFiles.length > 0) {
	// 		// console.log(subsidiaryFiles)
	// 	  const zip = new JSZip();
	// 	  const fetchPromises = subsidiaryFiles.map(async (file: any) => {
	// 		const response = await fetch(file.url);
	// 		const blob = await response.blob();
	// 		console.log(blob)
	// 		zip.file(file.url.split('/').pop(), blob);
	// 	  });

	// 	  await Promise.all(fetchPromises);

	// 	  zip.generateAsync({ type: 'blob' }).then((content) => {
	// 		saveAs(content, 'files.zip');
	// 	  });
	// 	}
	//   }

	const fetchAccountManagers = async () => {
		dispatch(openLoader());
		const url = `/users/all/minimal`;
		try {
			const { data }: any = await client.get(url);
			setAccountManagers(data.data);
			dispatch(closeLoader());
			return data?.data || [];
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error.response.data;

			// Log the error message
			console.error('Error fetching businesses:', message);

			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};


	const {
		businessInformation,
		businessRegistration,
		personalInformation,
		groupInformation,
		stakeholders,
		// contact_information,
		bankDetails,
		wallets,
	} = businessDetails;

	interface Stakeholder {
		bvn: string | null;
		nin: string | null;
		firstName: string | null;
		lastName: string | null;
		gender: string | null;
		dateOfBirth: string | null;
		mobileNumber: string | null;
	}

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Business Details
						</div>
						<div className={styles.buttonWrapper}>
							<button
								onClick={() => setOpenDetailsUpdateModal({ isOpen: true, fieldName: 'status', currentValue: businessInformation?.status })}
								className={styles.downloadbutton}>
								Update Merchant Status
							</button>
							<button
								onClick={() => { setIsOpenSingle(true); fetchAccountManagers() }}
								className={styles.downloadbutton}>
								Verify Merchant
							</button>
							{/* <button
								onClick={downloadMerchantFiles}
								className={styles.downloadbutton}>
								Download Merchant Files
							</button> */}
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>
								{businessInformation?.businessName}
							</h3>
							<div
								onClick={() => setList({ ...list, firstList: !list.firstList })}
								className={styles.detailicon}>
								{list.firstList && <CloseIcon />}
								{!list.firstList && <AddIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
								display: list.firstList ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Merchant ID</div>
										<div className={styles.detailsKey}>
											{businessInformation?.id || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Core Merchant ID</div>
										<div className={styles.detailsKey}>
											{businessInformation?.merchantId || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Business Type</div>
										<div className={styles.detailsKey}>
											{businessInformation?.businessType || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Country</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.country || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Sign Up Date</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.dateCreated || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Status</div>
										<div className={styles.detailsKey}>
											<StatusView
												status={businessInformation?.status || 'Not Provided'}
												blue='Pending-Verification'
												orange='Pending-Approval'
												green='Active'
												red='New'
											/>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>MCC</div>
										<div className={styles.detailsKey}>
											{businessInformation?.mccCode ? `${businessInformation?.mccCode} - ${businessInformation?.mcc}` : 'Not Provided'}
											<button onClick={() => setOpenDetailsUpdateModal({ isOpen: true, fieldName: 'mccid', currentValue: businessInformation?.mccCode })} className={styles.updateButton}>Update</button>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account Manager ID</div>
										<div className={styles.detailsKey}>
											{businessInformation?.mccCode ? `${businessInformation?.accountManagerId} - ${businessInformation?.accountManagerId}` : 'Not Provided'}
											<button onClick={() => setOpenDetailsUpdateModal({ isOpen: true, fieldName: 'AccountManagerId', currentValue: businessInformation?.accountManagerId })} className={styles.updateButton}>Update</button>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Referral ID</div>
										<div className={styles.detailsKey}>
											{businessInformation?.referralID ? `${businessInformation?.referralId} - ${businessInformation?.referralId}` : 'Not Provided'}
											<button onClick={() => setOpenDetailsUpdateModal({ isOpen: true, fieldName: 'ReferralId', currentValue: businessInformation?.referralId })} className={styles.updateButton}>Update</button>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Personal Information</h3>
							<div>

								<button
									onClick={() => setOpenDetailsUpdateModal({ isOpen: true, useFullScreen: true, fieldName: 'personal_information', currentValue: personalInformation })}
									className={styles.downloadbutton}>
									Update Personal Information
								</button>
								<span
									style={{ marginLeft: "20px" }}
									onClick={() =>
										setList({ ...list, secondList: !list.secondList })
									}
									className={styles.detailicon}>
									{list.secondList && <CloseIcon />}
									{!list.secondList && <AddIcon />}
								</span>
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
								display: list.secondList ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>BVN</div>
										<div className={styles.detailsKey}>
											{' '}
											{personalInformation?.bvn || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>First name</div>
										<div className={styles.detailsKey}>
											{personalInformation?.firstName || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Last name</div>
										<div className={styles.detailsKey}>
											{personalInformation?.lastName || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Gender</div>
										<div className={styles.detailsKey}>
											{personalInformation?.gender || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Date of Birth</div>
										<div className={styles.detailsKey}>
											{personalInformation?.dob || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Phone number</div>
										<div className={styles.detailsKey}>
											{personalInformation?.mobileNumber || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Identification document
										</div>
										<div className={styles.detailsKey}>
											{personalInformation?.idDocument}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Identification number
										</div>
										<div className={styles.detailsKey}>
											{personalInformation?.idNumber || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Identification</div>
										<div className={styles.detailsKey}>
											<ViewFile name={personalInformation?.identification} file={personalInformation?.identification} />
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Passport photograph
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={personalInformation?.passportPhoto} file={personalInformation?.passportPhoto} />
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Proof of address</div>
										<div className={styles.detailsKey}>
											<ViewFile name={personalInformation?.proofOfAddress} file={personalInformation?.proofOfAddress} />
										</div>
									</div>
								</Grid>{' '}
							</Grid>
						</div>
					</div>

					{stakeholders && stakeholders.length > 0 ? (
						stakeholders.map((stakeholder: Stakeholder, index: number) => (
							<div key={index} className={styles.detailBox}>
								<div className={styles.detailBoxCorner}>
									<h3 className={styles.detailh3}>Shareholder Details ({index + 1})</h3>
									<div
										onClick={() => setList({ ...list, ninthList: !list.ninthList })}
										className={styles.detailicon}
									>
										{list.ninthList ? <CloseIcon /> : <AddIcon />}
									</div>
								</div>

								<Divider style={{ color: '#CECECD' }} />

								<div
									style={{
										padding: '0 24px',
										display: list.ninthList ? '' : 'none',
									}}
								>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6} md={2} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>BVN</div>
												<div className={styles.detailsKey}>
													{stakeholder.bvn || 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={2} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>NIN</div>
												<div className={styles.detailsKey}>
													{stakeholder.nin || 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>First name</div>
												<div className={styles.detailsKey}>
													{stakeholder.firstName || 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={2} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>Last name</div>
												<div className={styles.detailsKey}>
													{stakeholder.lastName || 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>Gender</div>
												<div className={styles.detailsKey}>
													{stakeholder.gender || 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>Date of Birth</div>
												<div className={styles.detailsKey}>
													{stakeholder.dateOfBirth ? new Date(stakeholder.dateOfBirth).toLocaleDateString("en-GB") : 'Not Provided'}
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>Phone number</div>
												<div className={styles.detailsKey}>
													{stakeholder.mobileNumber || 'Not Provided'}
												</div>
											</div>
										</Grid>
									</Grid>
								</div>
							</div>
						))
					) : (
						<></>
					)}

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Business Information</h3>
							<div>
								<button
									onClick={() => setOpenDetailsUpdateModal({ isOpen: true, useFullScreen: true, fieldName: 'business_information', currentValue: businessInformation })}
									className={styles.downloadbutton}>
									Update Business Information
								</button>
								<span
									style={{ marginLeft: "20px" }}
									onClick={() =>
										setList({ ...list, fifthList: !list.fifthList })
									}
									className={styles.detailicon}>
									{list.fifthList && <CloseIcon />}
									{!list.fifthList && <AddIcon />}
								</span>
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
								display: list.fifthList ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											What do you do at {businessInformation?.businessName}
										</div>
										<div className={styles.detailsKey}>
											{businessInformation?.description || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Business email</div>
										<div className={styles.detailsKey}>
											{businessInformation?.emailAddress || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Business phone number
										</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.mobileNumber || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Business address</div>
										<div className={styles.detailsKey}>
											{businessInformation?.address || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>City</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.city || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>State</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.state || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Website</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.website || 'Not Provided'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Facebook</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.facebook || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Instagram</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.instagram || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Twitter</div>
										<div className={styles.detailsKey}>
											{' '}
											{businessInformation?.twitter || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Business logo</div>
										<div className={styles.detailsKey}>
											<ViewFile file={businessInformation?.logo} name={businessInformation?.logo} />
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Business Registration</h3>
							<div>
								{businessInformation?.businessType?.toLowerCase() == "company" && <button
									onClick={() => setOpenDetailsUpdateModal({ isOpen: true, useFullScreen: true, fieldName: 'business_registration', currentValue: businessRegistration })}
									className={styles.downloadbutton}>
									Update Business Registration
								</button>}
								<span
									style={{ marginLeft: "20px" }}
									onClick={() =>
										setList({ ...list, thirdList: !list.thirdList })
									}
									className={styles.detailicon}>
									{list.thirdList && <CloseIcon />}
									{!list.thirdList && <AddIcon />}
								</span>
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
								display: list.thirdList ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Registration Number</div>
										<div className={styles.detailsKey}>
											{businessRegistration?.registrationNumber || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Business class</div>
										<div className={styles.detailsKey}>
											{businessRegistration?.businessClass || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Tax identification number
										</div>
										<div className={styles.detailsKey}>
											{businessRegistration?.tin || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Estimated Monthly Volumn
										</div>
										<div className={styles.detailsKey}>
											{businessRegistration?.expectedMonthlyTransactionVolumn ? numberWithCommas(businessRegistration?.expectedMonthlyTransactionVolumn) : 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Certificate of incorporation
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={businessRegistration?.certOfIncorporation} file={businessRegistration?.certOfIncorporation} />
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Particulars of director
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={businessRegistration?.operatingLicense} file={businessRegistration?.operatingLicense} />
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Statement of return on allotment of shares
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={businessRegistration?.statementOfShares} file={businessRegistration?.statementOfShares} />
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Memo of Association
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={businessRegistration?.memoOfAssociation} file={businessRegistration?.memoOfAssociation} />
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Operating License
										</div>
										<div className={styles.detailsKey}>
											<ViewFile name={businessRegistration?.operatingLicense} file={businessRegistration?.operatingLicense} />
										</div>
									</div>
								</Grid>
								{
									businessRegistration?.otherDocuments?.map((doc: any) => doc.uploadedBy != "Admin" && (
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>{doc.documentName}</div>
												<div className={styles.detailsKey}>
													<ViewFile name={doc?.value} file={doc?.value} />
												</div>
											</div>
										</Grid>
									))
								}
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<div>
								<h3 className={styles.detailh3}>Compliance Uploaded Documents</h3>
							</div>
							<div

								className={styles.detailicon}>
								<button
									onClick={() => setdocumentModalIsOpenSingle(true)}
									className={styles.downloadbutton}>
									Upload Additional Documents
								</button>
								<span
									style={{ marginLeft: "20px" }}
									onClick={() => setList({ ...list, complianceUploadDocs: !list.complianceUploadDocs })}
								>
									{list.complianceUploadDocs && <CloseIcon />}
									{!list.complianceUploadDocs && <AddIcon />}
								</span>
							</div>
						</div>
						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
								display: list.complianceUploadDocs ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								{
									businessRegistration?.otherDocuments?.map((doc: any) => doc.uploadedBy == "Admin" && (
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<div className={styles.customerInfo}>
												<div className={styles.detailsValue}>{doc.documentName}</div>
												<div className={styles.detailsKey}>
													<ViewFile name={doc?.value} file={doc?.value} />
												</div>
											</div>
										</Grid>
									))
								}
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Bank details ({bankDetails?.length || 0})</h3>
							<div
								onClick={() => setList({ ...list, sixthList: !list.sixthList })}
								className={styles.detailicon}>
								{list.sixthList && <CloseIcon />}
								{!list.sixthList && <AddIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						{bankDetails?.map((account: any) => <div
							style={{
								padding: '0 24px',
								display: list.sixthList ? '' : 'none',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account type</div>
										<div className={styles.detailsKey}>
											{account?.accountType || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Bank name</div>
										<div className={styles.detailsKey}>
											{account?.bankName || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account number</div>
										<div className={styles.detailsKey}>
											{account?.accountNumber || 'Not Provided'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account name</div>
										<div className={styles.detailsKey}>
											{account?.accountName || 'Not Provided'}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>)}
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>
								Fees & Limit Configurations Group ( {groupInformation?.name} )
								<button onClick={() => setOpenDetailsUpdateModal({ isOpen: true, fieldName: 'subsidiaryGroupId', currentValue: groupInformation?.id })} className={styles.updateButton}>Update Configuration Group</button>
								<button onClick={() => setOpenCustomizeConfigModal(true)} className={styles.configureNewGroup}>Configure New Group</button>
							</h3>
							<div
								onClick={() => setList({ ...list, seventhList: !list.seventhList })}
								className={styles.detailicon}>
								{list.seventhList && <CloseIcon />}
								{!list.seventhList && <AddIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<SubsidiaryConfigSection groupInformation={groupConfigInformation} display={list.seventhList} subdiaryInfo={businessDetails} />
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>
								Preferences
							</h3>
							<div
								onClick={() => setList({ ...list, subPreference: !list.subPreference })}
								className={styles.detailicon}>
								{list.subPreference && <CloseIcon />}
								{!list.subPreference && <AddIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<SubsidiaryPreferenceSection groupInformation={groupConfigInformation} display={list.subPreference} subdiaryInfo={businessDetails} />
					</div>

					{/* Additional Preferences Section */}
					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Additional Preferences</h3>
							<div
								onClick={() => setList({ ...list, additionalsubPreference: !list.additionalsubPreference })}
								className={styles.detailicon}>
								{!list.additionalsubPreference ? <AddIcon /> : <CloseIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						{/* Display the AdditionalPreferences component with the necessary props */}
						<SubsidiaryAdditionalPreferenceSection
							groupInformation={groupConfigInformation}
							display={list.additionalsubPreference}
							businessDetails={businessDetails}
							subId={businessInformation?.id}
						/>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<h3 className={styles.detailh3}>Terms and conditions</h3>
							<div
								onClick={() =>
									setList({ ...list, eightList: !list.eightList })
								}
								className={styles.detailicon}>
								{list.eightList && <CloseIcon />}
								{!list.eightList && <AddIcon />}
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '24px 24px',
								display: list.eightList ? '' : 'none',
							}}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className={styles.accept}>
									<div className={styles.accept_top}>
										<h4>Kindly accept the terms and conditions to proceed</h4>{' '}
										<Exclaim />
									</div>

									<div className={styles.accept_top}>
										<Checkbox
											checked={
												businessInformation?.acceptedTermsAndCondition ===
													true
													? true
													: false
											}
											sx={{
												color: '#8E173E',
												'&.Mui-checked': {
													color: '#69b088',
												},
											}}
											disabled
										/>
										<h3>
											{businessInformation?.name} accepted the Transactpay Business terms and conditions
										</h3>
									</div>
								</div>
							</Grid>
						</div>
					</div>
				</>
			)
			}
			<Modal
				isOpen={modalIsOpenSingle}
				onRequestClose={closeModalSingle}
				contentLabel='Example Modal'
				style={customStyles}>
				<Verify closeModal={closeModalSingle} fn={fetchDetails} id={id} accountManagers={accountManagers} />
			</Modal>
			<Modal
				isOpen={documentModalIsOpenSingle}
				onRequestClose={closeDocumentModalSingle}
				contentLabel='Example Modal'
				style={computeCustomStyles()}>
				<UploadAdditionalDocument closeModal={closeDocumentModalSingle} fn={fetchDetails} id={id} />
			</Modal>
			<Modal
				isOpen={openDetailsUpdateModal.isOpen}
				onRequestClose={() => closeDetailsUpdateModal()}
				contentLabel='Example Modal 1'
				style={openDetailsUpdateModal.useFullScreen ? computeCustomStyles() : customStyles}>
				<UpdateDetailModal closeModal={closeDetailsUpdateModal} isFullScreen={openDetailsUpdateModal.useFullScreen} fieldName={openDetailsUpdateModal.fieldName} fn={fetchDetails} id={id} />
			</Modal>
			<Modal
				isOpen={openCustomizeConfigModal}
				onRequestClose={() => setOpenCustomizeConfigModal(false)}
				contentLabel='Example Modal 1'
				style={computeCustomStyles()}>
				<UpdateSubsidiaryConfigModal groupInformation={groupConfigInformation} subsidiary={businessInformation} closeModal={() => setOpenCustomizeConfigModal(false)} cb={fetchDetails} />
			</Modal>

		</div >
	);
};

export default BusinessDetails;
