/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useCallback } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  TransactionsData,
  ColumnTransactions,
} from "../../types/TablesTypes/Transactions";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import TransactionDetails from "./TransactionDetails/TransactionDetails";
import { Dayjs } from "dayjs";
import FilterModal from "../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
  setTimeOnDate,
} from "../../utils/datefunction";
import FlagTransactionModal from "../../components/ModalContent/FlagTransactionModal/FlagTransactionModal";
import reports from "../../api/report";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../constants/date-event-filters";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Awaiting-Confirmation" },
  { id: 4, name: "Failed" },
  { id: 5, name: "Reversed" },
  { id: 6, name: "Processing" },
  { id: 7, name: "Successful" },
];

const AllTransactions = ({ etransactions, cb, showDateFilter = true }: any) => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [transactionId, setTransactionId] = useState<number | null>(null);
  const [transactions, setTransactions] = useState<any>(etransactions);

  const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
    isOpened: false,
    data: null,
  });

  function closeModalSingle() {
    setIsOpenSingle({ isOpened: false, data: null });
    cb && cb();
  }

  const { currency } = useSelector((state) => state.appSettingsReducer ?? {});

  const getTransactionsApi = useApi(transactionsApi.getTransactions);
  // const settlementApiHandler = useApi(settlementApi.toggleFlagSettlement);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const history = useHistory();
  const getPaymentMethods = useApi(reports.getPaymentMethods);
  const mapPaymentOptions = () =>
    (getPaymentMethods?.data?.data ?? []).map((x: any) => {
      return { name: x.name, value: x.code };
    });
  const paymeth = mapPaymentOptions();

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [externalReference, setExternalreference] = useState("");
  const [arn, setArn] = useState("");
  const [subsidiaryId, setSubsidiaryId] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [status, setStatus] = useState("");
  const [cardType, setCardType] = useState("");
  const [providerBank, setProviderBank] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [email, setEmail] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setExternalreference("");
    setStatus("");
    setCardType("");
    setProviderBank("");
    setTransactionType("");
    setSubsidiaryId("");
    setcustomerId("");
    setPaymentMethod("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  useEffect(() => {
    if (eventDate === "today") {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === "last7days") {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === "last30days") {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === "oneyear") {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  let filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Successful", value: "successful" },
        { name: "Initiated", value: "initiated" },
        { name: "Failed", value: "failed" },
        { name: "Pending", value: "pending" },
        { name: "Processing", value: "processing" },
        { name: "Awaiting-Confirmation", value: "awaiting_confirmation" },
        { name: "Reversed", value: "reversed" },
      ],
    },
    {
      name: "Card Type",
      value: cardType,
      setValue: setCardType,
      type: "select",
      selective: [
        { name: "Visa", value: "visa" },
        { name: "Verve", value: "verve" },
        { name: "Mastercard", value: "mastercard" },
      ],
    },
    {
      name: "Provider Bank",
      value: providerBank,
      setValue: setProviderBank,
      type: "text",
      //   selective: [
      // 	{ name: "Wema", value: "wema" },
      // 	{ name: "EasyPay", value: "easypay" },
      //   ],
    },
    {
      name: "Transaction Types",
      value: transactionType,
      setValue: setTransactionType,
      type: "select",
      selective: [
        { name: "Card", value: "C" },
        { name: "Bank Transfer", value: "bank-transfer" },
        { name: "QR Code", value: "NQR" },
        // { name: "USSD", value: "ba" },
      ],
    },
    {
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      name: "RRN",
      value: externalReference,
      setValue: setExternalreference,
      type: "text",
    },
    {
      name: "ARN",
      value: arn,
      setValue: setArn,
      type: "text",
    },
    {
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
    {
      name: "Merchant Id",
      value: subsidiaryId,
      setValue: setSubsidiaryId,
      type: "text",
    },
    {
      name: "Customer Id",
      value: customerId,
      setValue: setcustomerId,
      type: "text",
    },
  ];

  if (showDateFilter) {
    filteredArray = [
      {
        name: "Date Range",
        value: fromDate,
        setValue: setFromDate,
        type: "date", // Explicitly set as "date"
      },
      ...filteredArray,
    ];
  }

  //new addition to get adminID
  const [adminid, setAdminid] = useState<string>("");

  useEffect(() => {
    const fetchAdminID = async () => {
      try {
        const response: any = await client.get("/me");
        const id = response.data.user.id;
        setAdminid(id);
        // console.log('admin id from business', id);
      } catch (error) {
        console.error("Error fetching admin ID:", error);
      }
    };

    fetchAdminID();
  }, []);

  useEffect(() => {
    if (adminid) {
      getPaymentMethods.request();
      // Admin ID is set, you can now call fetchBusinesses
      fetchTransactions(); // Or handle any logic that needs adminID
      // console.log('Admin ID has been set:', adminid);
    }
  }, [adminid]); // Dependency array watches for changes to adminID

  const getBackendValue = () => {
    switch (transactionType) {
      case "Card":
        return "C";
      case "Bank Transfer":
        return "bank-transfer";
      default:
        return "";
    }
  };

  useEffect(() => {
    setTransactions(etransactions);
  }, [etransactions]);

  const fetchTransactions = async () => {
    // Ensure status is properly formatted
    const formattedStatus = status.replace(/-|\s/g, "");
    try {
      const backendValue = getBackendValue(); // Convert friendly name to backend value
      if (etransactions) return;
      dispatch(openLoader());

      // Construct the URL with adminid as a query parameter
      // const url = `order/all/paginated?subsidiaryId=${subsidiaryId}&currency=${currency}&customerId=${customerId}&email=${email}&status=${formattedStatus}&paymentreference=${paymentreference}&externalReference=${externalReference}&paymentmethod=${paymentMethod}&orderreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`;

      const url =
        `order/all/paginated` +
        `${subsidiaryId ? `?subsidiaryId=${subsidiaryId}` : "?"}` +
        `${currency ? `&currency=${currency}` : ""}` +
        `${customerId ? `&customerId=${customerId}` : ""}` +
        `${email ? `&email=${email}` : ""}` +
        `${formattedStatus ? `&status=${formattedStatus}` : ""}` +
        `${cardType ? `&cardType=${cardType}` : ""}` +
        // `${transactionType ? `&transactionType=${transactionType}` : ''}` +
        `${providerBank ? `&providerBank=${providerBank}` : ""}` +
        `${paymentreference ? `&paymentreference=${paymentreference}` : ""}` +
        `${transactionType ? `&paymentmethod=${transactionType}` : ""}` +
        `${
          externalReference ? `&externalReference=${externalReference}` : ""
        }` +
        `${arn ? `&arn=${arn}` : ""}` +
        `${orderreference ? `&orderreference=${orderreference}` : ""}` +
        `&fromdate=${setTimeOnDate(fromDate, 0, 0, 0)}` + 
        `&todate=${setTimeOnDate(toDate, 23, 59, 59)}` + 
        `&limit=${rowsPerPage}` +
        `&page=${pageNumber}`;

      const { data }: any = await client.get(url);
      if (data.statusCode == 404) {
        setTransactions([]);
        setTableRow([]);
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: "No content available for the selected filters.",
            toastStyles: ToastErrorStyles,
          })
        );
      } else {
        setTransactions(data.data);
        // setBearer(true)
        let payments = data.data;
        // console.log({ payments })
        setPageNumber(payments?.page?.currentPage || 1);
        // setBearer(false)
        // setPreviousPage(payments?.page?.currentPage - 1);
        // setNextPage(payments?.page?.currentPage + 1);
        if (etransactions) setTransactions(payments);
      }
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  const fetchTransactionExports = async () => {
    const formattedStatus = status.replace(/-|\s/g, "");
    let allData: any[] = [];
    let page = 1;
    const limit = 10000; // Adjust the limit as needed
    try {
      dispatch(openLoader());
      const url = `order/all/paginated?subsidiaryId=${subsidiaryId}&currency=${currency}&customerId=${customerId}&email=${email}&status=${formattedStatus}&paymentreference=${paymentreference}&cardType=${cardType}&paymentmethod=${paymentMethod}&externalReference=${externalReference}&orderreference=${orderreference}&fromdate=${setTimeOnDate(fromDate, 0, 0, 0)}&todate=${setTimeOnDate(toDate, 23, 59, 59)}&limit=${limit}&page=${page}`;
      const { data }: any = await client.get(url);
      allData = allData.concat(data.data.items);

      // Transform data to remove unwanted fields
      const filteredData = allData.map((item) => {
        // Destructure and remove specified fields, return the rest
        const {
          paymentType,
          subsidiaryFeeFormatted,
          customerFee,
          total,
          customFeeFormatted,
          datePaymentConfirmed,
          appEnvironment,
          ...rest
        } = item;
        return rest;
      });

      dispatch(closeLoader());
      return filteredData;
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
      return []; // Return an empty array in case of error
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchTransactions();
    }
  }, [bearer, pageNumber, rowsPerPage, email, currency]);

  useEffect(() => {
    // if (pageNumber) {
    setBearer(true);
    // }
  }, [pageNumber, email, rowsPerPage, currency]);

  //   useEffect(() => {
  // 	setBearer(true)
  //     let payments = etransactions ?? transactions;
  //     // console.log({ payments })
  //     setPageNumber(payments?.page?.currentPage || 1);
  // 	setBearer(false)
  //     // setPreviousPage(payments?.page?.currentPage - 1);
  //     // setNextPage(payments?.page?.currentPage + 1);
  //     if (etransactions) setTransactions(payments);
  //   }, [transactions, etransactions, bearer]);

  const menuOptions = [
    {
      id: 1,
      name: (t: any) => {
        return "View Transaction";
      },
      icon: ViewIcon,
      onclick: (t: any) => {
        setTransactionId(t?.order?.id ?? t?.id);
      },
    },
    {
      id: 2,
      name: (t: any) => {
        return t.settlementStatusId == 5 || t.settlementStatusId == 4
          ? "Settlement processed"
          : t.settlementStatusId == 2
          ? "Unflag Settlement"
          : "Flag Settlement";
      },
      icon: ViewIcon,
      onclick: (t: any) => {
        if (t.settlementStatusId == 5 || t.settlementStatusId == 4) return;
        setIsOpenSingle({ isOpened: true, data: t });
      },
    },
  ];

  const dataTransactions = useCallback(() => {
    const tempArr: TransactionsData[] = [];

    transactions?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        return tempArr.push({
          date: moment(transaction?.dateCreated).format("MMMM Do YYYY, h:mm a"),
          merchantName:
            transaction?.subsidiaryName ?? transaction?.order?.subsidiary?.name,
          merchantId: transaction?.subsidiaryId,
          id: transaction?.order?.id ?? transaction?.id,
          customerId:
            transaction?.customerId ?? transaction?.order?.customer?.id,
          customerName:
            transaction?.customerName ??
            `${transaction?.order?.customer?.firstName} ${transaction?.order?.customer?.lastName}`,
          email:
            transaction?.customerEmail ??
            transaction?.order?.customer?.emailAddress,
          transactionAmount: `${
            transaction?.currency?.shortName ?? transaction?.currency
          } ${transaction?.amountFormatted ?? transaction?.transactionAmount}`,
          paymentChannel:
            transaction?.paymentTypeName ??
            transaction?.order?.paymentTypeName ??
            transaction?.order?.paymentType ??
            "NIL",
          status: (
            <StatusView
              status={transaction?.orderStatus ?? transaction?.status?.name}
              green="Successful"
              blue="Initiated"
              orange="Pending"
              red="Failed"
              purple="Reversed"
            />
          ),
          action: !etransactions ? (
            ""
          ) : (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(transaction);
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(transaction)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(transaction)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  }, [transactions]);

  useEffect(() => {
    setTableRow(dataTransactions());
  }, [transactions?.items, dataTransactions]);


  return (
    <div style={{ minHeight: "100vh" }}>
      {!transactionId ? (
        <>
          <TableHeader
            pageName={
              etransactions ? "Settlement Transaction" : "All Transactions"
            }
            data={transactions?.items}
            dataLength={transactions?.page?.total}
            value={email}
            setValue={setEmail}
            dropdown={dropdown}
            setDropdown={setDropdown}
            searchfn={etransactions ? false : true}
            placeHolder="Search by customer email address"
            goBack={cb}
            exporting={true}
            fetchDataHandler={fetchTransactionExports}
            FilterComponent={
              <FilterButtonNew
                eventDate={eventDate}
                setEventDate={setEventDate}
                dropdown={dropdown}
                setDropdown={setDropdown}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
                setBearer={setBearer}
                clearHandler={clearHandler}
                clearAllHandler={clearHandler}
                applyAllHandler={applyAllHandler}
                filteredArray={filteredArray}
              />
            }
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <CreateCustomer
              fetchtransactions={fetchTransactions}
              closeModal={closeModal}
            />
          </Modal>
          {getTransactionsApi.loading ? (
            <div className={styles.loading} />
          ) : (
            <PaginationTable
              data={tableRow ? tableRow : []}
              columns={ColumnTransactions ? ColumnTransactions : []}
              value={value}
              emptyPlaceHolder={
                // 	transactions?.page?.total == 0
                !transactions ||
                transactions.length === 0 ||
                !transactions.items?.length
                  ? "You currently do not have any transactions"
                  : "Loading..."
              }
              total={transactions?.page?.total}
              totalPage={transactions?.page?.totalPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              nextPage={nextPage}
              clickAction={
                etransactions ? null : (obj) => setTransactionId(obj.id)
              }
              setNextPage={setNextPage}
              previousPage={previousPage}
              setPreviousPage={setPreviousPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </>
      ) : (
        <TransactionDetails id={transactionId} setId={setTransactionId} />
      )}

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle.isOpened}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <FlagTransactionModal
          closeModal={closeModalSingle}
          data={modalIsOpenSingle.data}
        />
      </Modal>
    </div>
  );
};

export default AllTransactions;
