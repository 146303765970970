import React, { Fragment, useState, useEffect } from "react";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { ColumnSubsidiariesPer } from "../../types/TablesTypes/Subsidiaries";
import StatusView from "../../components/StatusView/StatusView";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import useApi from "../../hooks/useApi";
import transactionsApi from "../../api/transactions";
import moment from "moment";
import { numberWithCommas } from "../../utils/formatNumber";
import { Dayjs } from "dayjs";
import FilterModal from "../../components/filterConfig/FilterModal";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../utils/datefunction";
import { ColumnCustomers } from "../../types/TablesTypes/Customers";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../constants/date-event-filters";

function Cust({ id, name }: { id: number; name: string }) {
  const [transactionsDetails, setTransactionDetails] = useState<any>([]);
  const [tableRow, setTableRow] = useState<any[]>();
  const gettransactionsApi = useApi(transactionsApi.getTransactions);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [country, setCountry] = useState("");

  const [named, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setCountry("");
    setName("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };

  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Email Address",
      value: email,
      setValue: setEmail,
      type: "text",
    },

    {
      name: "Country",
      value: country,
      setValue: setCountry,
      selective: [
        { name: "Nigeria", value: "Nigeria" },
        { name: "Kenya", value: "Kenya" },
        { name: "Ghana", value: "Ghana" },
      ],
      type: "select",
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "New", value: "New" },
        { name: "Active", value: "Active" },
        { name: "Pending-Verification", value: "Pending-Verification" },
        { name: "Pending-Approval", value: "Pending-Approval" },
      ],
      type: "select",
    },
  ];
  // useEffect(() => {
  // 	dispatch(openLoader());
  // 	client
  // 		.get(
  // 			`customer/all/paginated?subsidiaryid=${id}&customername=${named}&status=${status}&email=${email}&country=${country}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
  // 		)
  // 		.then((res: any) => {
  // 			// console.log("paginated", res?.data);
  // 			setTransactionDetails(res?.data);
  // 			dispatch(closeLoader());
  // 			setBearer(false);
  // 		})
  // 		.catch((error) => {
  // 			dispatch(closeLoader());
  // 			setIsLoading(false);
  // 			if (error.response) {
  // 				const message = error?.response?.data?.message;
  // 				dispatch(
  // 					openToastAndSetContent({
  // 						toastStyles: ToastErrorStyles,
  // 						toastContent: message,
  // 					})
  // 				);
  // 			} else if (error.request) {
  // 				console.log(error.request);
  // 			} else {
  // 				console.log('Error', error.message);
  // 			}
  // 		});
  // }, [bearer, named, pageNumber, rowsPerPage]);

  const fetchCustomers = async () => {
    try {
      dispatch(openLoader());
      const response = await client.get(
        `customer/all/paginated?subsidiaryid=${id}&customername=${named}&status=${status}&email=${email}&country=${country}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
      );
      setTransactionDetails(response?.data);
      setBearer(false);
    } catch (error: any) {
      if (error.response) {
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: error.response.data.message,
          })
        );
      } else {
        console.error("Error fetching transactions:", error.message);
      }
    } finally {
      dispatch(closeLoader());
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCustomers();
    }
  }, [bearer, named, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(transactionsDetails?.currentPage || 1);
    setPreviousPage(transactionsDetails?.previousPage);
    setNextPage(transactionsDetails?.nextPage);
  }, [transactionsDetails]);

  const dataTransactions = () => {
    const tempArr: any[] = [];
    transactionsDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((customers: any, index: number) => {
        return tempArr.push({
          customerName: customers?.customerName,
          emailAddress: customers?.emailAddress,
          mobileNumber: customers?.mobileNumber,
          country: customers?.countryName,
          transactionCount: customers?.transactionCount,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransactions);
  }, [transactionsDetails?.items]);

  const itemLength = transactionsDetails?.items?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="Customers"
        data={transactionsDetails?.items}
        dataLength={transactionsDetails?.totalItems}
        value={named}
        setValue={setName}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search by customer name"
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      {gettransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnCustomers ? ColumnCustomers : []}
          emptyPlaceHolder={
            transactionsDetails?.totalItems == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
          value={value}
          total={transactionsDetails?.totalItems}
          totalPage={transactionsDetails?.totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </Fragment>
  );
}

export default Cust;
