import React, { useEffect, useState } from 'react';
import styles from './Refund.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';

function Single({ closeModal, fn }: any) {
	interface initTypes {
		transactionReference: string;
		amount: number | undefined;
	}

	const initProps: initTypes = {
		transactionReference: '',
		amount: undefined,
	};
	const [inputs, setInputs] = React.useState(initProps);
	const dispatch = useDispatch();

	const [isLogged, setisLogged] = useState(false)

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('refund/transaction', [{ ...inputs }])
			.then((data: any) => {
				dispatch(closeLoader());
				closeModal();
				dispatch(
					openToastAndSetContent({
						toastContent: data?.data?.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				fn && fn();
			})
			.catch((error: any) => {
				const message = error?.response?.data?.message ?? "Something went wrong trying to process refund";
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			})
			.finally(() => {
				dispatch(closeLoader());
			});
	};

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const inputName = event.target.name;
		setInputs((prevState: initTypes) => {
			return {
				...prevState,
				[inputName]: newValue,
			};
		});
	};

	const disabledFunction = () => {
		if (
			inputs.transactionReference === '' ||
			(inputs.amount && isNaN(inputs.amount) === true) ||
			!inputs.amount
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{!isLogged && (
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Log a single refund</h3>
						<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div className={styles.modalcontent}>
						<div className={styles.input}>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='amount'
								inputLabel='Amount'
								inputValue={inputs.amount}
								style={{ width: '100%' }}
							/>
						</div>
	
						<div className={styles.input}>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='transactionReference'
								inputLabel='Transaction reference'
								inputValue={inputs.transactionReference}
								style={{ width: '100%' }}
							/>
						</div>
	
						<Divider style={{ margin: 0, padding: 0 }} />
	
						<div className={styles.modalFooter}>
							<button
								disabled={disabledFunction()}
								onClick={() => {setisLogged(true)}}
								className={styles.fund}>
								Log Refund
							</button>
							
							<button className={styles.cancel} onClick={closeModal}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}

			{isLogged && (
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Confirmation</h3>
						<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div className={styles.modalcontent}>
						<div className={styles.input}>
							<h5>You are about to Log a Refund</h5>
							<p> <br/> Do you want to proceed?</p>
						</div>


						<Divider style={{ margin: 0, padding: 0 }} />

						<div className={styles.modalFooter}>
							<button className={styles.cancel} onClick={() => {setisLogged(false); closeModal()}} 	style={{backgroundColor:'#AA0000', color:'white'}}>
								No
							</button>

							<button
								disabled={disabledFunction()}
								onClick={(e) => {handleSubmit(e); setisLogged(false)}}
								className={styles.fund}
								style={{backgroundColor:'#006400'}}
								>
								Yes, Log Refund
							</button>
						</div>
					</div>
				</div>
			)}

		</>
	);
}

export default Single;
